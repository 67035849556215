import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Dashboard from "../components/Dashboard";

// import ApplicationFormContainer from "../containers/ApplicationFormContainer";
const ApplicationFormContainer = React.lazy(() =>
  import("../containers/ApplicationFormContainer")
);
// import ApplicationFormPreviewContainer from "../containers/ApplicationFormPreviewContainer";
const ApplicationFormPreviewContainer = React.lazy(() =>
  import("../containers/ApplicationFormPreviewContainer")
);
// import ApplicationSuccessContainer from "../containers/ApplicationSuccessContainer";
const ApplicationSuccessContainer = React.lazy(() =>
  import("../containers/ApplicationSuccessContainer")
);
const ApplicationFeeFormContainer = React.lazy(() =>
  import("../containers/ApplicationFeeFormContainer")
);
const ApplicationFeeSuccessContainer = React.lazy(() =>
  import("../containers/ApplicationFeeSuccessContainer")
);
const WatchVideoDownloadPDFContainer = React.lazy(() =>
  import("../containers/WatchVideoFormContainer")
);
const PhoneCallFormContainer = React.lazy(() =>
  import("../containers/PhoneCallFormContainer")
);
const BlankEnrollementContainer = React.lazy(() =>
  import("../containers/BlankEnrollementContainer")
);
const AgDisclosureContainer = React.lazy(() =>
  import("../containers/AgDisclosureContainer")
);
const ProgramCostWorksheetContainer = React.lazy(() =>
  import("../containers/ProgramCostWorksheetContainer")
);

const ReviewCatalogue = React.lazy(() =>
  import("../containers/ReviewCatalogueContainer")
);

const UploadDocumentForm = React.lazy(() =>
  import("../containers/UploadDocumentFormContainer")
);

const AcknowledgementDocument = React.lazy(() =>
  import("../containers/AcknowledgementDocumentContainer")
);

const EnrollmentAgreement = React.lazy(() =>
  import("../containers/EnrollmentAgreementContainer")
);
const EditProfileForm = React.lazy(() =>
  import("../containers/EditProfileFormContainer")
);
const BackgroundCheckESign = React.lazy(() =>
  import("../containers/BackgroundCheckESignContainer")
); //
const AppointmentFormContainer = React.lazy(() =>
  import("../containers/AppointmentFormContainer")
); //

const PostLoginRoute = () => {
  return (
    <>
      <Switch>
        <Route exact path={["/", "/Home"]} component={Dashboard} />
        <Route
          exact
          path="/Home/Form/:activityId?"
          component={ApplicationFormContainer}
        />
        <Route
          path="/Home/ApplicationForm/Preview/:activityId?"
          component={ApplicationFormPreviewContainer}
        />
        <Route
          path="/Home/ApplicationSuccess/:activityId?"
          component={ApplicationSuccessContainer}
        />
        <Route
          exact
          path="/Home/ApplicationFeeForm/:activityId?"
          component={ApplicationFeeFormContainer}
        />
        <Route
          path="/Home/ApplicationFeeSuccess/:activityId?"
          component={ApplicationFeeSuccessContainer}
        />
        <Route
          exact
          path="/Home/PhoneCallForm/:activityId?"
          component={PhoneCallFormContainer}
        />
        <Route
          exact
          path="/Home/WatchVideoForm/:activityId?"
          component={WatchVideoDownloadPDFContainer}
        />
        <Route
          path="/Home/AgProgram/disclosure/:activityId"
          component={AgDisclosureContainer}
        />
        <Route
          path="/Home/BlankEnrollement/:activityId"
          component={BlankEnrollementContainer}
        />
        <Route
          path="/Home/Sign/BackgroundCheck/:activityId"
          component={BackgroundCheckESign}
        />
        <Route
          path="/Home/ProgramcostWorksheet/:activityId"
          component={ProgramCostWorksheetContainer}
        />
        <Route
          path="/Home/ReviewCatalogue/:activityId"
          component={ReviewCatalogue}
        />
        <Route
          path="/Home/UploadDocumentForm/:activityId"
          component={UploadDocumentForm}
        />
        <Route
          path="/Home/AcknowledgementForm/:activityId"
          component={AcknowledgementDocument}
        />
        <Route
          path="/Home/EnrollmentAgreement/:activityId"
          component={EnrollmentAgreement}
        />
        <Route
          // path="/Home/AppointmentForm/:activityId"
          path="/Home/AppointmentForm"
          component={AppointmentFormContainer}
        />
        <Route path="/profile" component={EditProfileForm} />

        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

export default PostLoginRoute;
