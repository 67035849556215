import React, { useState } from "react";
const HelpModal = React.lazy(() => import("../Dashboard/helpModal"));
const ContactCareerProgram = () => {
  const [helpModal, setHelpModal] = useState(false);
  return (
    <div className="ml-sm-auto mt-2">
      <span
        style={{
          color: "#6c757d",
        }}
      >
        <i className="fa fa-envelope "></i>
        <i className="fa fa-phone mx-2"> </i>
        Contact Your :{/* <i className="fa fa-user ml-2"></i> */}
      </span>
      <br />
      <span
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          fontSize: "17px",
          fontWeight: " bold",
          color: "#00476c",
        }}
        onClick={() => setHelpModal(true)}
      >
        Career Program Specialist
      </span>
      <HelpModal helpModal={helpModal} setHelpModal={setHelpModal}></HelpModal>
    </div>
  );
};

export default ContactCareerProgram;
