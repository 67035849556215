import config from "../config/index";
import queryString from "query-string";

const getAuthSessionInfoCookieValue = (propertyName) => {
  let cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)auth-session-info\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  let val;
  try {
    val = JSON.parse(cookieValue)[propertyName];
  } catch (e) {
    val = {};
  }
  return val;
};
const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
const isAuthSessionInfoCookiePresent = () => {
  return document.cookie.indexOf("auth-session-info") >= 0;
};

const AuthHandler = {
  getActivityId: () => {
    return getCookie("activityId");
  },
  getUser: () => {
    let auth = getCookie("auth-session-info");
    if (auth) {
      auth = JSON.parse(auth);
      return auth.authSessionState.user;
    }
    return false;
  },
  GetAuthKey: () => {
    return isAuthSessionInfoCookiePresent()
      ? getAuthSessionInfoCookieValue("authSessionState").authKey
        ? getAuthSessionInfoCookieValue("authSessionState").authKey
        : config.AuthKey
      : config.AuthKey;
  },
  GetLeadId: () => {
    return isAuthSessionInfoCookiePresent()
      ? getAuthSessionInfoCookieValue("authSessionState").leadId
        ? getAuthSessionInfoCookieValue("authSessionState").leadId
        : ""
      : "";
  },
//   GetAccessKey: () => {
//     return config.AccessKey;
//   },
//   GetSecretKey: () => {
//     return config.SecretKey;
//   },
  GetErrorMessage: (errorObj) => {
    return errorObj.response
      ? errorObj.response.data.ExceptionMessage
      : "There was an error processing the request";
  },
  GetConfiguredAuthKey: () => {
    return config.AuthKey;
  },
  SetCookie: (cookieName, value) => {
    document.cookie = `${cookieName}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`;
  },
  RemoveCookie: (cookieName) => {
    document.cookie = `${cookieName}=""; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  },
  SetAuthSessionInfoCookie: (authSessionState) => {
    let now = new Date();
    let time = now.getTime();
    time += 1800 * 2 * 1000;
    now.setTime(time);
    let utmValues = queryString.parse(window.location.search);
    let utmObj = {
      utm_source_campaign: utmValues.utm_source_campaign,
      utm_source_name: utmValues.utm_source_name,
      utm_source_medium: utmValues.utm_source_medium,
      utm_source_term: utmValues.utm_source_term,
      utm_source_content: utmValues.utm_source_content,
    };
    if (JSON.stringify(utmObj) === JSON.stringify({})) {
      utmObj = getAuthSessionInfoCookieValue("utmObj");
    }
    document.cookie = `auth-session-info=${JSON.stringify({
      authSessionState: authSessionState,
      utmObj: utmObj,
    })};expires=${now.toUTCString()};path=/`;
  },
  GetAuthSessionInfoCookieValue: (propertyName) => {
    return getAuthSessionInfoCookieValue(propertyName);
  },
  ExpireAuthSessionInfoCookie: () => {
    document.cookie =
      "auth-session-info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
  },
  IsAuthSessionInfoCookiePresent: isAuthSessionInfoCookiePresent,
  SaveActiveTabIdInCookie: () => {
    if (document.cookie.indexOf("active-tab-id") >= 0) {
    }
  },
  GetUTMObject: () => {
    return getAuthSessionInfoCookieValue("utmObj");
  },
};

export default AuthHandler;
