const ERROR_MSG = {
  REQUIRED: "Required",
  PASSWORD_PATTERN:
    "Should contain atleast 8 characters and 1 numeric character",
  PASSWORD_MISMATCH: "Your password and confirmation password do not match",
  SERVER_ERROR: "Something went wrong. Please try again.",
  ALREADY_LOGIN: "You are Already Logged in",
};

export { ERROR_MSG };
