import React from "react";
import ReactTelInput from "react-telephone-input";
// require('react-telephone-input/lib/withStyles')
import Spinner from "react-bootstrap/Spinner";
// import withStyles from 'react-telephone-input/lib/withStyles';
import 'react-telephone-input/css/default.css';
import "./style.css";

const Input = ({
  type,
  handleChange,
  handleBlur,
  value,
  name,
  error,
  label,
  mandatory,
  placeholder,
  verified,
  onGenerateOtp,
  sideLink,
  status,
}) => {
  const field = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      {sideLink && sideLink}
      <input
        type={type}
        className={error ? "form-control formError" : "form-control"}
        placeholder={placeholder}
        name={name}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        value={value}
        required
      />
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const emailVerifyField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <div className={`verification-wrapper ${verified && "otp-verified"}`}>
        <input
          type="email"
          className={error ? "form-control formError" : "form-control"}
          placeholder={placeholder}
          name={name}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          value={value}
          required
        />
        <div className="verification-button">
          {status ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <span
              className={`verify-button ${!value ? "disabled" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                onGenerateOtp();
              }}
            >
              Verify
            </span>
          )}
          <p className="otp-success">Verified</p>
        </div>
      </div>
      {error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const phoneVerifyField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <div className={`verification-wrapper ${verified && "otp-verified"}`}>
        <ReactTelInput
          defaultCountry="in"
          flagsImagePath="/flags.723494a4.png"
          onChange={(value, selectedCountry) =>
            handleChange(value, selectedCountry)
          }
          onBlur={handleBlur}
        />
        <div className="verification-button">
          {status ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <span
              className={`verify-button ${!value ? "disabled" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                onGenerateOtp();
              }}
            >
              Verify
            </span>
          )}
          <p className="otp-success">Verified</p>
        </div>
      </div>
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );

  const phoneField = (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {mandatory && <span>*</span>}
      </label>
      <ReactTelInput
        defaultCountry="in"
        flagsImagePath="/flags.723494a4.png"
        onChange={(value, selectedCountry) =>
          handleChange(value, selectedCountry)
        }
        onBlur={handleBlur}
      />
      {!!error && <p className="formErrorText">{error}</p>}
    </div>
  );

  switch (type) {
    case "text":
    case "email":
    case "password":
      return field;

    case "email-verify":
      return emailVerifyField;

    case "phone":
      return phoneField;

    case "phone-verify":
      return phoneVerifyField;

    default:
  }
};

export default Input;
