import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ApplicationHistory from "../components/Dashboard/ApplicationHistory";

const mapStateToProps = (state) => {
  return {
    authSession: state.authSessionInfo,
    leadDetails: state.leadDetails,
  };
};

export default withRouter(connect(mapStateToProps)(ApplicationHistory));
