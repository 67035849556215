import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import BackgroundCheck from "./Modal/BackgroundCheck";
import config from "../../config";
import {
  API_ActivityDetailsById,
  // API_GetLeadsById,
} from "../../api/lead-activity-api";
import moment from "moment";
import "./style.css";
// import { style } from "react-dom-factories";
const blue = "#007bff";

const ApplicationCard = ({
  application,
  applicationStatus,
  user,
  modifiedOn,
  applicationCTA,
  applicationReview,
  applicationId,
  paymentStatus = "",
  educationalFunding = null,
  assessmentOpenInNew = false,
  showTeas = false,
  shouldDownloadDocument = {},
  initiateBGCheck = false,
  setIframeYoutube,
  setVideoModal,
  AGPdfUrl,
  PCWPdfUrl,
  EAPdfUrl,
}) => {
  // console.log("shoulddownloaddocument", shouldDownloadDocument);
  const isDownloadDocFound = Object.values(shouldDownloadDocument).filter(
    function (v) {
      return !!v;
    }
  ).length;
  // console.log("isDownloadDocFound", isDownloadDocFound);
  const [documentDownloadLink, setDocumentDownloadLink] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const setYoutube = (e, link) => {
    e.preventDefault();
    // console.log("calld");
    setIframeYoutube(link);
    setVideoModal(true);
  };
  /*   console.log("application", application);
  console.log("application card", paymentStatus);
  console.log("applicationCTA", applicationCTA); */

  showTeas =
    config.TEAS_TEST_FOR_PROGRAMS[
      application?.ActivityFields?.mx_Custom_1 &&
        JSON.parse(application?.ActivityFields?.mx_Custom_1)?.mx_CustomObject_2
    ]; // show teas test throughout card
  const getDownloadLinkForDocument = (object) => {
    // console.log("getDownloadLinkForDocument", object);
    const names = {
      mx_Custom_18: "Download AG Disclosure document",
      mx_Custom_19: "Download PCW document",
      mx_Custom_20: "Download EA document",
    };
    if (
      applicationStatus !== "Accepted" &&
      object.SchemaName === "mx_Custom_20"
    )
      return null;
    return (
      <a href={object.FileUrl} target="_blank" rel="noopener noreferrer">
        {names[object.SchemaName]}
      </a>
    );
  };
  const getDocumentLinks = (activityId) => {
    API_ActivityDetailsById({ activityId })
      .then((res) => {
        const filteredFields = [];
        const { Fields } = res.data.message;
        Fields.filter(
          (e) =>
            [
              "mx_Custom_20",
              "mx_Custom_19",
              "mx_Custom_18",
              "mx_Custom_2",
            ].indexOf(e.SchemaName) !== -1 &&
            filteredFields.push({
              SchemaName: e.SchemaName,
              Value: e.Value,
              FileUrl:
                e.CustomObjectFormProperties?.FieldProperties?.FormMetaData[1]
                  ?.FileURL,
            })
        );
        // console.log(filteredFields[0].Value);
        /*   sessionStorage.setItem(
          "LSQ_DOCUMENT_STATUS" + application?.RelatedProspectId,
          filteredFields[0].Value
        ); */
        filteredFields.splice(0, 1);
        // console.log(filteredFields);
        const links = filteredFields
          .filter((e) => e.FileUrl !== null)
          .reverse()[0];
        // links &&
        //   sessionStorage.setItem(
        //     "LSQ_DOCUMENT_LINKS" + application?.RelatedProspectId,
        //     JSON.stringify(links)
        //   );
        links && setDocumentDownloadLink(links);
        // console.log("filteredFields", links);
      })
      .catch(console.log);
  };
  useEffect(() => {
    // TODO:  add condition to prevent checking unless one of siggnow is signed.
    // console.log("dsd", AGPdfUrl, "--", PCWPdfUrl, "--", EAPdfUrl);
    // console.log("status", applicationStatus);
    const LSQ_DOC_LINKS = sessionStorage.getItem(
      "LSQ_DOCUMENT_LINKS" + application?.RelatedProspectId
    );
    const links =
      LSQ_DOC_LINKS === "undefined" ? false : JSON.parse(LSQ_DOC_LINKS);

    setDocumentDownloadLink(links);
    // console.log("links__", links);
    if (
      application &&
      application.Id &&
      isDownloadDocFound // any doc found in EA,PCW,AG then only call this method oterwise dont
      /* applicationStatus !==
        sessionStorage.getItem(
          "LSQ_DOCUMENT_STATUS" + application?.RelatedProspectId
        ) */
      // &&  !sessionStorage.getItem(
      //   "LSQ_DOCUMENT_LINKS" + application?.RelatedProspectId
      // )
    ) {
      getDocumentLinks(application.Id);
    }
  }, []);
  let applicationStatusColor = (status) => {
    let applicationStatus = status;
    if (!status) {
      return false;
    }
    status = status?.toLowerCase();
    let style = "";
    if (status.includes("pending")) {
      //style = "#E6BB44"; //orange
      style = blue; //blue
    }
    if (status.includes("reenroll")) {
      style = blue;
    }
    if (status.includes("fail")) style = blue;
    if (
      status.includes("pass") ||
      status.includes("accepted") ||
      status.includes("approved")
    )
      style = blue;
    if (
      status.includes("in review") ||
      status.includes("in process") ||
      status.includes("background") ||
      status.includes("saved")
    )
      style = blue;
    return (
      <span style={{ color: style }}>
        {applicationStatus ? applicationStatus : "-"}
      </span>
    );
  };

  let paymentStatusColor = (status) => {
    let paymentStatus = status;
    status = status.toLowerCase();
    let style = "";
    if (status.includes("pending") || status.includes("waived")) style = blue;
    if (status.includes("paid")) style = blue; //green
    return (
      <span style={{ color: style }}>
        {paymentStatus ? paymentStatus : "-"}
      </span>
    );
  };

  const applicationModifiedFormat = (str) => {
    return moment(str).add(5.5, "h").format("DD MMMM, YYYY");
  };
  // console.log("application", application.ActivityFields);
  const activity = application.ActivityFields;
  const course = activity.mx_Custom_1 && JSON.parse(activity.mx_Custom_1);
  /* const applicationId =
       application.ActivityFields && application.ActivityFields.mx_Custom_17
         ? application.ActivityFields.mx_Custom_17
         : ""; */
  const HelpIcon = ({ status }) => {
    const filterArr = {
      "Register & Take Wonderlic Test":
        "https://www.youtube.com/embed/ee5JGOE4Ynw",
      "Massachusetts Documents 1": "https://www.youtube.com/embed/uvi8UuwSKrE",
      "Massachusetts Documents 2": "https://www.youtube.com/embed/uvi8UuwSKrE",
      "Massachusetts Documents 3": "https://www.youtube.com/embed/uvi8UuwSKrE",
    };
    const result = filterArr[status];
    return result ? (
      <a
        href="/"
        className="ml-2"
        style={{ color: "#6c757d " }}
        onClick={(e) => setYoutube(e, result)}
      >
        <i className="fa fa-info-circle" style={{ color: "#6c757d" }}></i>
      </a>
    ) : (
      ""
    );
  };
  return (
    <>
      <BackgroundCheck
        BackgroundCheck={backgroundCheck}
        setBackgroundCheck={setBackgroundCheck}
        activityId={application.Id}
      />

      <div className="h-100" data-aos="fade-up" data-aos-delay="100">
        <div className="card border-0 instructionCardShadow mt-4 pt-2 pt-md-0 mt-md-0 h-100">
          <div className="card-body">
            <div className="row h-100">
              <div className="col-12">
                <div className="d-flex h-100 align-items-center">
                  <table className="acTable">
                    <tbody>
                      <tr>
                        <td>Student Name</td>
                        <td>{user && user.firstName + " " + user.lastName}</td>
                      </tr>
                      <tr>
                        <td>Application ID</td>
                        <td>{(applicationId && applicationId) || "-"}</td>
                      </tr>
                      <tr>
                        <td>Campus Name</td>
                        <td>{(course && course.mx_CustomObject_1) || "-"}</td>
                      </tr>
                      <tr>
                        <td>Program Name</td>
                        <td>{(course && course.mx_CustomObject_2) || "-"}</td>
                      </tr>
                      <tr>
                        <td>Enrollment Status</td>
                        <td>
                          {applicationStatus === ""
                            ? applicationStatusColor("Application Pending")
                            : applicationStatusColor(applicationStatus) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Fee Status</td>
                        <td>
                          {(paymentStatus &&
                            paymentStatusColor(paymentStatus)) ||
                            "-"}
                        </td>
                      </tr>
                      {educationalFunding && (
                        <tr>
                          <td>Educational Funding Status</td>
                          <td>{educationalFunding}</td>
                        </tr>
                      )}

                      {
                        <>
                          {activity &&
                            (activity?.mx_Custom_6 ||
                              applicationStatus.toLowerCase() ===
                                "application in review") && (
                              <tr>
                                <td>Wonderlic Test Result</td>
                                <td>
                                  {applicationStatusColor(
                                    activity.mx_Custom_6 || "-"
                                  )}
                                </td>
                              </tr>
                            )}

                          {activity &&
                            (activity?.mx_Custom_6?.toLowerCase() === "pass" ||
                              ["pass", "in review"].indexOf(
                                activity?.mx_Custom_10?.toLowerCase()
                              ) !== -1) &&
                            showTeas && (
                              <tr>
                                <td>TEAS Test Result:</td>
                                <td>
                                  {applicationStatusColor(
                                    activity?.mx_Custom_10 || "-"
                                  )}
                                </td>
                              </tr>
                            )}
                          {/* commented because wonderlic will onyl be shown now */}
                          {/* {activity &&
                          activity?.mx_Custom_6 &&
                          activity?.mx_Custom_6?.toLowerCase() === "pass" && (
                            <tr>
                              <td>School Readiness Assesment Result:</td>
                              <td>
                                {applicationStatusColor(
                                  activity?.mx_Custom_7 || "-"
                                )}
                              </td>
                            </tr>
                          )}

                        {activity &&
                          activity?.mx_Custom_7?.toLowerCase() === "pass" && (
                            <tr>
                              <td>Computer & Online Assement Result:</td>
                              <td>
                                {applicationStatusColor(
                                  activity?.mx_Custom_8 || "-"
                                )}
                              </td>
                            </tr>
                          )}

                        {activity &&
                          activity?.mx_Custom_8?.toLowerCase() === "pass" && (
                            <tr>
                              <td>Learning Style Assesment:</td>
                              <td>
                                {applicationStatusColor(
                                  activity?.mx_Custom_9 || "-"
                                )}
                              </td>
                            </tr>
                          )}

                        {activity &&
                          activity?.mx_Custom_9?.toLowerCase() === "pass" &&
                          showTeas && (
                            <tr>
                              <td>TEAS Test Result:</td>
                              <td>
                                {applicationStatusColor(
                                  activity?.mx_Custom_10 || "-"
                                )}
                              </td>
                            </tr>
                          )} */}
                        </>
                      }
                      <tr>
                        <td>Last Update</td>
                        <td>{applicationModifiedFormat(modifiedOn)}</td>
                      </tr>
                      {/* <tr>
                        <td>
                          <span className="color-red">Action</span>
                        </td>
                        <td className="applicationCardLinks">
                          {initiateBGCheck ? (
                            <a
                              href="/"
                              className="color-red"
                              onClick={(e) => {
                                e.preventDefault();
                                setBackgroundCheck(true);
                              }}
                            >
                              {applicationCTA.text}
                            </a>
                          ) : (
                            applicationCTA &&
                            ((assessmentOpenInNew && ( // if we dont do this here then will open with hostname as prefix
                              <a
                                href={applicationCTA.link}
                                target="_blank"
                                className="color-red"
                                rel="noopener noreferrer"
                              >
                                {applicationCTA.text}
                              </a>
                            )) || (
                              <Link
                                to={applicationCTA.link}
                                style={{
                                  textDecoration:
                                    applicationCTA.text === "Background Check"
                                      ? "none"
                                      : "",
                                }}
                              >
                                <>
                                  {applicationCTA.text ===
                                  "Background Check" ? (
                                    <span
                                      data-mdb-toggle="tooltip"
                                      title="Processing by users"
                                      className="color-red "
                                      style={{ textDecoration: "none" }}
                                    >
                                      {applicationCTA.text}
                                    </span>
                                  ) : (
                                    <span className="color-red red-underline">
                                      {applicationCTA.text}
                                    </span>
                                  )}
                                </>
                              </Link>
                            ))
                          )}
                          <HelpIcon status={applicationCTA.text} />
                          {applicationReview && (
                            <>
                              <span className="mx-2">|</span>
                              <Link to={applicationReview.link}>
                                {applicationReview.text}
                              </Link>
                            </>
                          )}
                        </td>
                      </tr> */}
                      {AGPdfUrl ||
                      PCWPdfUrl ||
                      EAPdfUrl ||
                      applicationReview ? (
                        <tr>
                          <td>Documents</td>
                          <td className="download-link-wrapper">
                            <div className="document-download-link">
                              {applicationReview && (
                                <>
                                  <Link to={applicationReview.link}>
                                    Application
                                  </Link>
                                </>
                              )}
                            </div>
                            {AGPdfUrl && applicationReview ? (
                              <span className="mx-2 separator">|</span>
                            ) : (
                              ""
                            )}
                            <div className="document-download-link">
                              {AGPdfUrl && (
                                <a href={AGPdfUrl} target="_blank">
                                  AG Program Disclosure
                                </a>
                              )}
                            </div>
                            {PCWPdfUrl ? (
                              <span className="mx-2 separator">|</span>
                            ) : (
                              ""
                            )}
                            <div className="document-download-link">
                              {PCWPdfUrl && (
                                <a href={PCWPdfUrl} target="_blank">
                                  Program Cost Worksheet
                                </a>
                              )}
                            </div>
                            {EAPdfUrl ? (
                              <span className="mx-2 separator">|</span>
                            ) : (
                              ""
                            )}
                            <div className="document-download-link">
                              {EAPdfUrl && (
                                <a href={EAPdfUrl} target="_blank">
                                  Enrollment Agreement
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                      {/* {documentDownloadLink &&
                        getDownloadLinkForDocument(documentDownloadLink) && (
                          <tr>
                            <td>Documents</td>
                            <td className="applicationCardLinks">
                              {getDownloadLinkForDocument(documentDownloadLink)}
                            </td>
                          </tr>
                        )} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ApplicationCard);
