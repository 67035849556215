import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./style.css";
import AuthHandler from "../../utility/AuthHandler";
import TransparentLoader from "../Loader/transparentLoader";
import {
  // API_GetLeadsById,
  API_UpdateLeadsById,
} from "../../api/lead-activity-api";

let NoticeModal = ({ setIframeYoutube, setVideoModal }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const readImportantNotice = JSON.parse(sessionStorage.getItem("user-info"));
  useEffect(() => {
    /*  API_GetLeadsById({
      leadId: AuthHandler.GetLeadId(),
    })
      .then((response) => {
        const leadDetails = response.data && response.data[0];
        let readImportantNotice = leadDetails?.mx_Read_Important_Notice;

        console.log(leadDetails.mx_Read_Important_Notice);
        if ([null, "No", "no"].includes(readImportantNotice)) {
          setDisplayModal(true)
        }
        setDisplayLoader(false);
      }).catch((err) => {
        console.log("error while fetching lead details");
      }); */

    // console.log(
    //   "readImportantNotice",
    //   readImportantNotice?.mx_Read_Important_Notice
    // );
    if (
      [null, "No", "no"].includes(readImportantNotice?.mx_Read_Important_Notice)
    ) {
      setDisplayModal(true);
    }
    setDisplayLoader(false);
  }, []);

  let updateReadInformation = () => {
    setDisplayLoader(true);
    const payload = {
      id: AuthHandler.GetLeadId(),
      data: [
        {
          Attribute: "mx_Read_Important_Notice",
          Value: "Yes",
        },
      ],
    };
    API_UpdateLeadsById(payload)
      .then((res) => {
        // console.log("++", res);
        setDisplayLoader(false);
        setDisplayModal(false);
        Object.assign(
          readImportantNotice,
          {},
          { mx_Read_Important_Notice: "Yes" }
        );
        // console.log("after readImportantNotice", readImportantNotice);
        sessionStorage.setItem(
          "user-info",
          JSON.stringify(readImportantNotice)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setYoutube = (e, link) => {
    e.preventDefault();
    console.log("calld");
    setIframeYoutube(link);
    setVideoModal(true);
  };
  return (
    <>
      {displayLoader && <TransparentLoader />}
      <div className="">
        <Modal
          show={displayModal}
          onHide={() => true}
          dialogClassName="noticeModalWrapper"
          style={{ background: "rgba(0,0,0,0.6)" }}>
          <Modal.Header>
            <h3 className="font-weight-bold">Important Notice</h3>
          </Modal.Header>

          <Modal.Body>
            <div>
              <p>
                Using Google Chrome &nbsp;
                <a
                  href="/"
                  onClick={(e) =>
                    setYoutube(e, "https://www.youtube.com/embed/Z8K5fvs0kbs")
                  }>
                  Watch
                </a>
              </p>
              {/* <p className="text-justify boldImportantWords">
                Welcome! We're excited that you're ready to take the next step
                in the enrollment process! In order for you to complete the
                application, you
                <span>
                  {" "}
                  <u>must</u>
                </span>{" "}
                have a <span> High School Diploma, GED</span> or college
                degree.There will be a step where you have to upload proof of
                this, so please make sure you have this or you're on track to
                obtain it before you start your program with us.
              </p>
              <p className="text-justify boldImportantWords">
                Once again, if you're currently taking classes to complete your{" "}
                <span> High School Diploma</span> or <span> GED</span>, you're
                welcome to continue the application process until the step where
                you need to upload your documentation. Please reach out to our
                team if you have any questions.
              </p> */}
              <p className="text-justify boldImportantWords">
                Welcome! We're excited that you're ready to take the next step
                in the enrollment process! In order for you to complete the
                application you need to have OR be on track to obtain a valid{" "}
                <span> high school diploma or GED </span>(HS equivalency).
                Please reach out to our team if you have any questions.
                Congratulations!
              </p>
              <p>
                {" "}
                Enrolling After Graduation &nbsp;
                <a
                  href="/"
                  onClick={(e) =>
                    setYoutube(e, "https://www.youtube.com/embed/Bi6Iab8FlZo")
                  }>
                  Watch
                </a>
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer className="px-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-xl-9">
                  <div className="d-flex px-2 pb-3">
                    <input
                      type="checkbox"
                      id="in1"
                      className="in-checkbox mt-1"
                      checked={checkboxValue}
                      onChange={() => {
                        setCheckboxValue(!checkboxValue);
                      }}
                    />

                    <label
                      htmlFor="in1"
                      className="pl-3 text-justify font-italic font-weight-bold">
                      I understand and confirm that I have read above given
                      information.
                    </label>
                  </div>
                </div>

                {checkboxValue && (
                  <div className="col-12 col-xl-3">
                    <button
                      className="btn in-button ml-auto d-block"
                      onClick={updateReadInformation}>
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NoticeModal;
