import axios from "axios";
import AuthHandler from "../utility/AuthHandler";
import { LSQ_API } from "../constants/endpoint";
import { WRAPPER_LAPP_URLS } from "./wrapperLappUrl";

const { V2_API_WRAPPER, PORTAL_SIGN_IN_API_WRAPPER } = WRAPPER_LAPP_URLS;

var lsqApiInstance = axios.create({
  baseURL: LSQ_API.BASE,
  timeout: 15000,
  headers: { Authorization: AuthHandler.GetAuthKey() },
});

const API_GetLeadsById = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "get",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.GET_LEAD_BY_ID,
    params: {
      id: payload.leadId,
    },
  });
  return promise;
};
const API_UpdateLeadsById = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "POST",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.UPDATE_LEAD,
    params: {
      LeadId: payload.id || payload.leadId,
    },
    data: payload.data,
  });
  return promise;
};

const API_GetLeadActivity = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "post",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.GET_LEAD_ACTIVITY,
    params: {
      leadId: payload.leadId,
    },
    data: {
      Parameter: {
        ActivityEvent: payload.activityEvent,
      },
    },
  });
  return promise;
};

const API_CreateActivity = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "post",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.CREATE_ACTIVITY,
    params: {},
    data: payload,
  });
  return promise;
};

const API_UpdateActivity = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "post",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.UPDATE_ACTIVITY,
    params: {},
    data: payload,
  });
  return promise;
};

const API_ActivityDetailsById = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "get",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.GET_ACTIVITY_DETAILS_BY_ID,
    params: {
      activityId: payload.activityId,
      getFileURL: true,
    },
  });
  return promise;
};
const API_SignnowAccessTokenLapp = (body) => {
  return lsqApiInstance({
    method: "POST",
    headers: {
      "x-api-key": "mQ0xdRoYTF51U13QsxffH5GtzJVDyl1takVGuNhS",
      "Content-Type": "application/json",
    },
    url: "https://lapps-us11.leadsquared.com/execute?name=da_46476_6dbe9ca5&stage=Test",
    data: body,
    json: true,
  });
};
const API_documentUpload = (body) => {
  return lsqApiInstance({
    method: "POST",
    headers: {
      "x-api-key": "mQ0xdRoYTF51U13QsxffH5GtzJVDyl1takVGuNhS",
      "Content-Type": "application/json",
    },
    url:
      "https://lapps-us11.leadsquared.com/execute?name=da_46476_796ad42f&stage=" +
      ([
        "yticareerinstituteandporterandchester-bhg7gvew.lsqportal-test.com",
        "localhost",
      ].indexOf(window.location.hostname) !== -1
        ? "Test"
        : "Live"),
    data: body,
    json: true,
  });
};
const API_GetLeadOwnerDetails = (payload) => {
  const promise = axios.post(V2_API_WRAPPER, {
    method: "get",
    headers: "",
    endpoint: LSQ_API.ENDPOINTS.GET_LEAD_OWNER,
    params: {
      LeadIdentifier: "ProspectID",
      value: AuthHandler.GetLeadId(),
    },
  });
  return promise;
};
export {
  API_GetLeadsById,
  API_GetLeadActivity,
  API_UpdateActivity,
  API_ActivityDetailsById,
  API_CreateActivity,
  API_UpdateLeadsById,
  API_SignnowAccessTokenLapp,
  API_GetLeadOwnerDetails,
  API_documentUpload,
};
