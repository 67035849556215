import React from "react";
// import hand from "../../images/hand.svg";
// import banner from "../../images/dashboard-banner.jpg";

import "./style.css";

const DashboardBanner = () => {
  return (
    <section
      className="dashboard-banner d-lg-flex align-items-center h-100 p-0 pt-4"
    // style={{ backgroundImage: `url(${banner})` }}
    >
      {/* <div className="container">
        {applyNow && <div className="apply-now-block">{applyNow}</div>}
      </div> */}
      <div className="text-center text-lg-left pl-lg-5 ml-lg-5">
        <p style={{ fontSize: "18px", color: "#263238" }}>
          You have not applied to any program.
        </p>
        <a
          className="btn text-white"
          href="/Home/Form"
          style={{ background: "#00476C" }}
        >
          Apply Now
        </a>
      </div>
    </section>
  );
};

export default DashboardBanner;
