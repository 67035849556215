import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import LogoutContainer from "../../containers/LogoutContainer";
import { getSessionData } from "../../utility/common";
import config from "../../config";
import {
  API_GetLeadActivity,
  API_ActivityDetailsById
} from "../../api/lead-activity-api";
import AuthHandler from "../../utility/AuthHandler";

const UserProfile = (props) => {
  const [leadDetails, setLeadDetails] = useState();
  const placement = "bottom";
  const [educationFundingStatus, setEducationFundingStatus] = useState("");
  useEffect(() => {
    const userInfo = props.user || getSessionData("user-info");
    // console.log(userInfo);
    setLeadDetails(userInfo);

    API_GetLeadActivity({
      leadId: AuthHandler.GetLeadId(),
      activityEvent: config.ACTIVITY_EVENT,
    })
      .then(function (response) {
        const application =  response.data.message.ProspectActivities[0];
        // if(application && application.Id){
        //   setActivityId(application.Id)
        // }

        API_ActivityDetailsById({ activityId: application.Id })
          .then((res) => {
            const activityDetails = res.data.message;
            setEducationFundingStatus(activityDetails.Fields[5].Value)
          })
          .catch((error) => {
            console.log(error, "activity API");
          });
      })

  }, []);

  return (
    <div
      id="userProfileOptions"
      className="user-profile-options"
      style={{ display: "flex", alignItems: "center" }}
    >
      <OverlayTrigger
        rootClose
        trigger={["focus", "click"]}
        key={placement}
        placement={placement}
        overlay={
          <Popover
            id={`popover-positioned-${placement}`}
            className="d-none d-lg-block"
            style={{
              // marginRight: "60px",
              marginTop: "10px",
              borderRadius: "5px!important",
            }}
          >
            <div className="user-profile-content">
              <ul className="dropdown ">
                <li className="pt-2">
                  <Link to="/profile">Edit Profile</Link>
                </li>
                {
                   educationFundingStatus === "" || educationFundingStatus === "Application Received and Entered" ?
                  null
                 :
                 <li className="pt-2">
                 <a href=" https://porterchester.edu/schedule" target="_blank">Book FA Appointment</a>
               </li>
        }
                
                <li className="py-2">
                  <LogoutContainer />
                </li>
              </ul>
            </div>
          </Popover>
        }
      >
        <span
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <span className="user-profile-icon-wrapper px-2">
            <i
              id="userProfileOptionsIcon"
              className="fa fa-user-circle"
              aria-hidden="true"
              style={{
                fontSize: 32,
                color: "#607D8B",
              }}
            ></i>
          </span>
          <span
            style={{
              color: "#607D8B",
              marginRight: 10,
              fontWeight: 500,
              fontSize: "16px",
              textTransform: "capitalize",
            }}
          >
            {leadDetails &&
              (leadDetails.firstName
                ? `${leadDetails.firstName} ${
                    leadDetails.lastName ? leadDetails.lastName : ""
                  }`
                : leadDetails.email)}
          </span>

          <i
            className="fa fa-chevron-down"
            style={{
              fontWeight: 200,
              fontSize: "12px",
              color: "#607D8B",
              transform: "translateY(-1px)",
            }}
          ></i>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default UserProfile;

