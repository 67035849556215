import React from "react";
import config from "../../config";
const stages = [
  ["Application Form", "Application Pending", ""], // inital application status should be null
  [
    "Application Fee Waived",
    "Application Fee",
    "Fee Pending",
    null, // null is explicitly for paymentStatus
  ],
  [
    "Assessment",
    // "Application in Review",
    "Application Approved",
    "Fee Waived",
    "Fee paid",
    "pending",
    "paid", // null
    "fail",
  ],
  [
    "Review Catalog",
    "Review Catalog Pending",
    "Teas Test Pass",
    "Teas Test in Review",
    "Teas Test pass",
    "Initiate Background check",
  ],
  ["Acknowledgment Form", "Acknowledgement Form Pending"],
  [
    "Upload Documents",
    "Educational funding",
    "Documents Pending",
    "Documents in review",
    "Documents in Review",
    "Documents Verified",
  ],

  [
    "Enrollment Agreement",
    "Enrollment agreement in process",
    "Enrollment agreement in Process",
    "Enrollment agreement parent e-sign Pending",
    "Acknowledge Form Done",
    "Enrollment Agreement E-Sign Pending",
    "Enrollment agreement e-sign Pending",
    "Enrollment agreement e-sign pending",
    "Enrollment agreement e-sign in review",
    "Enrollment agreement e-sign in Review",
  ],
  ["Accepted"],
];

const ApplicationStages = ({
  currentStage,
  paymentStatus,
  state,
  count,
  doBackgroundCheck,
}) => {
  // console.log("dobackground", doBackgroundCheck);
  paymentStatus = paymentStatus || null; // if paymentStatus status is false then make it null so that it mapped the index at payment
  if (count && stages[4].indexOf("Massachusetts Documents") === -1) {
    // madatory to check if mass index exists because go back from from review application below code get added everytime you visit the page and comes back
    // console.log("is it displaying 10 status?", stages, count, state);
    if (config.EA_MAP.STATES.indexOf(state) !== -1) {
      // console.log("user falls in mass", stages, count, state);
      // TODO:
      // if this is massachusetts
      stages.splice(4, 0, [
        // push this at 4th index and reindex
        "Massachusetts Documents",
        "Blank Enrolment Pending",
        // "Initiate Background check",
        "AG Program Disclosure E Sign Pending",
        "AG Program Disclosure E Sign in Review",
        "Program Cost Worksheet Pending",
        "Program Cost Worksheet in Review",
      ]); /// if the state is massachusetts then add this array in the mapping otherwise skip
    }
    if (
      doBackgroundCheck &&
      stages[4].indexOf("Background Check") === -1 &&
      stages[5].indexOf("Background Check") === -1 // when redirect back to page This code below gets added if user state is from hamden and brockton. To remove it we checked if the stages already includes background check then dont add otherweise add.
    ) {
      const atIndex = config.EA_MAP.STATES.indexOf(state) !== -1 ? 5 : 4;
      stages.splice(atIndex, 0, [
        "Background Check",
        "Background check in Process",
        "Background check to consider",
      ]);
    }
  }
  // console.log("paymentstatus", [paymentStatus, state, stages]);
  currentStage = currentStage && currentStage.trim(); // remove spacing from start and end.
  // console.log("currentStage", currentStage);
  return (
    <>
      <div className="card applicationStageCard border-0 instructionCardShadow h-100">
        <div className="card-body">
          <p className="bold mb-2"> Instruction:</p>
          <div className="">
            <ul
              className={`list-group px-2 application-stages ${
                !currentStage && "disabled"
              } `}>
              {stages.map((each, index) => (
                <li
                  className={`list-group-item border-0 py-2 ${
                    currentStage !== null && // application stage shouldn't be null and has activity count > 0  and application status is found in the stages or current loop array or payment is found in current loop array and current application state is application in review.
                    count &&
                    (each.indexOf(currentStage) !== -1 || // this is for application status
                      (each.indexOf(paymentStatus) !== -1 && // this is for payment only
                        currentStage.toLowerCase() === "application in review")) // this is for payment only
                      ? "active shadow "
                      : ""
                  } `}
                  style={{
                    // color: "#A1ADB2",
                    fontSize: "14px",
                    marginBottom: "2px",
                  }}
                  key={each[0]}>
                  Step {++index}: &nbsp; {each[0]}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationStages;
