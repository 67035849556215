/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_ResetPassword } from "../../api/signing-request";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import { ERROR_MSG } from "../../constants/error";
import { passwordScheme } from "../../utility/ValidationSchema";
import Input from "../Input";
import Button from "../Button";

const ResetPasswordForm = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const checkFormData = () => {
    if (!passwordScheme(password)) {
      setPasswordError(ERROR_MSG.PASSWORD_PATTERN);
      return false;
    } else {
      setPasswordError("");
    }

    if (!passwordScheme(confirmPassword)) {
      setConfirmPasswordError(ERROR_MSG.PASSWORD_PATTERN);
      return false;
    } else {
      setConfirmPasswordError("");
    }

    if (password !== confirmPassword) {
      setFormMessage(ERROR_MSG.PASSWORD_MISMATCH);
      return false;
    } else {
      setFormMessage("");
    }

    return true;
  };

  const onhandleSubmit = () => {
    const queryValue = new URLSearchParams(window.location.search);
    const queryLeadId = queryValue.get("leadId");
    const queryTempPassword = queryValue.get("tempPasswaord");
    const validation = checkFormData();

    if (validation) {
      setIsLoading(true);
      API_ResetPassword({
        LeadId: queryLeadId,
        TemporaryPassword: queryTempPassword,
        Password: password,
      })
        .then(function (response) {
        //   console.log(response.data);
          setFormMessage("Password Changed Successfully");
          setIsLoading(false);
          window.setTimeout(function () {
            props.history.push("/");
          }, 1500);
        })
        .catch(function (error) {
          setFormMessage(error.response.data.ExceptionMessage);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="form-skeleton">
          <div className="form-title-wrapper">
            <h3>Reset Password</h3>
          </div>
          {formMessage && <p className="form-message"> {formMessage}</p>}
          <form
            action=""
            method="post"
            className="form-wrapper-box"
            onSubmit={(e) => {
              e.preventDefault();
              onhandleSubmit();
            }}
          >
            <Input
              type="password"
              handleChange={(value) => {
                setPassword(value);
              }}
              handleBlur={() => {}}
              value={password}
              name="Password"
              error={passwordError}
              label="Password"
              mandatory={true}
              placeholder={FORM_PLACEHOLDER.PASSWORD}
            />
            <Input
              type="password"
              handleChange={(value) => {
                setConfirmPassword(value);
              }}
              handleBlur={() => {}}
              value={confirmPassword}
              name="Confirm Password"
              error={confirmPasswordError}
              label="Confirm Password"
              mandatory={true}
              placeholder={FORM_PLACEHOLDER.CONFIRM_PASSWORD}
            />

            <div class="form-group">
              <Button
                disabled={
                  password !== confirmPassword ||
                  password === "" ||
                  confirmPassword === ""
                }
                type="submit"
                className="btn-primary submit-button"
                text="Reset Password"
                status={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
