const WRAPPER_LAPP_URLS = {
    V2_API_WRAPPER: "https://lapps-us11.leadsquared.com/executebylapptoken?name=da_46476_5c87d74a&stage=Live&xapikey=37c0c116ea2a40fe9718405f5a4f4607",
}

export { WRAPPER_LAPP_URLS };







