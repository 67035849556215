import { Action } from "../actions/index";

function leadDetails(state, action) {
  let leadDetails = {
    firstName: "",
    lastName: "",
    email: "",
    mx_Read_Important_Notice: "",
    applicationID: "",
  };
  if (state) {
    switch (action.type) {
      case Action.LeadDetails.Update:
        leadDetails = Object.assign({}, state, {
          firstName: action.leadDetails.firstName,
          lastName: action.leadDetails.lastName,
          email: action.leadDetails.email,
          applicationID: action.leadDetails.applicationID,
          mx_Read_Important_Notice: action.leadDetails.mx_Read_Important_Notice,
        });
        // console.log("leadDetails insidne reducer", leadDetails);
        break;
      default:
        leadDetails = state;
        break;
    }
  }
  return leadDetails;
}

export default leadDetails;
