import React from "react";
import LeadsquaredLogo from "../../images/powered-by.svg";
import "./style.css";

const PoweredByLeadSquared = () => {
  return (
    <footer className="footer-wrapper" style={
      {
        background: "#F9FAFB",
        padding: "0px",
        height: "auto",
      }}>
      Powered by <img src={LeadsquaredLogo} alt="Leadsquared" height="20" className="mr-4 mb-2" />
    </footer>
  );
};

export default PoweredByLeadSquared;
