import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import config from "../../config";
import LoadingScreen from "../Loader/Loader";
// import TransparentLoader from "../Loader/transparentLoader";

import "./registrationForm.css";
// import queryString from "query-string";

import AuthHandler from "../../utility/AuthHandler";
// import LoginForm from "./LoginForm";
import { API_Login } from "../../api/signing-request";
import { API_GetLeadsById } from "../../api/lead-activity-api";
// import Loader from "react-loader-spinner";
import { /*  landingData, */ appLogo } from "../LandingLayout/data";

// import generateNewApplicationForm from '../Dashboard/GenerateNewApplicationForm';
// import ExitPopup from "./../ExitPopup";

/* global lsq_setupForm */

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      loader2: false,
      show: false,
    };
  }

  loadOverrideCSS = () => {
    // Changing the button Text
    window.lsqFormContainer.querySelector(".lsq-form-action-btn").textContent = "Sign Up"; //prettier-ignore

    window.lsqFormContainer
      .querySelector(".lsq-form-action-btn")
      .addEventListener("click", () => {
        // Accessing Password Error Message Element inner Text
        let passwordErrorMsg = window.lsqFormContainer.querySelector(
          ".mx-custom-password-textfield-focused + span"
        ).innerHTML;

        if (passwordErrorMsg === "Invalid Password") {
          // Replacing current text with CUSTOM text
          window.lsqFormContainer.querySelector(".mx-custom-password-textfield-focused + span").innerHTML =  "Should contain atleast 8 characters and 1 numeric character"; //prettier-ignore
        }
      });

    // window.lsqFormContainer.querySelector("lsq-form-confirm-password-item").querySelector("input").placeholder = "Confirm Password";
    //  console.log(window.lsqFormContainer.querySelector('#584503d0-1e79-11eb-9782-064afdf7f968__tab2__section2__LastName__Lead__0'));
    //Setting Loader to false
    $(
      '.lsq-form-password-item [data-schemaname="mx_Form_Special_Password"]'
    ).attr("placeholder", "Password *");
    $(
      '.lsq-form-confirm-password-item [data-schemaname="mx_Form_Special_Password"]'
    ).attr("placeholder", "Confirm Password *");
    // $('[data-schemaname="mx_Correspondence_State"] .select2-selection__rendered').html("Select State");
    // $('[data-schemaname="mx_Correspondence_City"] .select2-selection__rendered').html("Select City");
    // $('[data-schemaname="mx_Select_Campus_Prefrence"] .select2-selection__rendered').html("Select Campus");

    this.setState({ loader: false });
    // this.injectUTMParameters();
    let self = this;
    setTimeout(function () {
      self.setState({ show: true });
    }, 3000);
  };

  closeExitPop = () => {
    this.setState({ show: false });
  };

  // this function will get executed after successful signup
  login = (payload) => {
    const that = this;
    API_Login(payload)
      .then(function (response) {
        API_GetLeadsById({
          leadId: response.data.LeadId,
        })
          .then(function (res) {
            // console.log('signin successful ', response, res);
            const completeLeadDetails = res.data.message[0];
            const authSessionInfo = {
              isAuthenticated: true,
              leadId: response.data.LeadId,
              authKey: response.data.AuthKey,
            };

            if (completeLeadDetails) {
              const leadDetails = JSON.stringify({
                email: completeLeadDetails.EmailAddress,
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
                campusPrefrence: completeLeadDetails.mx_Select_Campus_Prefrence,
                courseName: completeLeadDetails.mx_Course_Opted,
                mx_Read_Important_Notice: "No",
              });
              sessionStorage.setItem("user-info", leadDetails);
            }
            // generateNewApplicationForm(response.data.LeadId);
            AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
            that.props.updateLoginInfo(authSessionInfo);
            that.props.updateLeadDetails(completeLeadDetails);

            //that.props.history.push("/");
            // setIsLoading(false);
            that.setState({ loader2: false });
          })
          .catch(function (error) {
            console.log(error, "error");
            window.location.href = "/";
            that.setState({ loader2: false });
          });
        // for after signup success message
        localStorage.setItem("isSuccess", true);
      })
      .catch(function (error) {
        that.setState({ loader2: false });
        // TODO set error message
        // window.location.href = "/";
      });
  };

  onLSQFormSubmissionSuccess = () => {
    window.lsqFormContainer.querySelector(".ms-message-txt").textContent =   "Account created successfully. You are now being logged in"; //prettier-ignore

    console.log(">>>> LSQ Form Submit <<<<");
    this.setState({ loader2: true });
    const email = document.querySelector(
      'input[data-schemaname="EmailAddress"]'
    ).value;
    const password = document.querySelector(
      'input[data-schemaname="mx_Form_Special_Password"]'
    ).value;

    // try {
    //   let formSubmitMessage = window.lsqFormContainer.querySelector(".lsq-form-global-msg-wrapper .ms-message-txt").innerHTML;
    //   if (formSubmitMessage === "Form submitted successfully") {
    //     window.lsqFormContainer.querySelector(".lsq-form-global-msg-wrapper .ms-message-txt").innerHTML = "Account created successfully. You are now being logged in";
    //   }
    //   // alert(window.lsqFormContainer.querySelector(".lsq-form-global-msg-wrapper .ms-message-txt").innerHTML);
    // }
    // catch (err) {
    //   console.log(err);
    // }

    this.login({ EmailAddress: email, Password: password });
  };
  onLSQFormSaveFailure = (e) => {
    window.lsqFormContainer.querySelector(".ms-message-txt").textContent =  "User already exists"; //prettier-ignore
  };

  componentDidMount() {
    $.getScript(config.lsqFormURL)
      .done(function (data) {
        lsq_setupForm({
          id: config.lsqRegistrationFormId,
          authKeyProvider: AuthHandler.GetAuthKey,
        });
      })
      .fail(function (error) {
        window.location.href = "/";
      });

    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );

      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LsqFormSaveFailure",
        this.onLSQFormSaveFailure
      );
    }
  }

  render() {
    return (
      <>
        {this.state.loader && <LoadingScreen />}
        {/* {this.state.loader && (
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )} */}

        <div className="application-form-container registration">
          <div className="logo-container">
            <img src={appLogo.path} alt={appLogo.alt} style={appLogo.style} />
          </div>
          <div
            style={{
              fontFamily: "Times New Roman",
              fontSize: "28px",
              color: "#06224A",
              textAlign: "center",
              marginTop: "15px",
            }}>
            Admissions Portal
          </div>
          <div className="form-toggle p-0 mt-3 mb-2">
            <div className="login-toggle mt-2 text-center">
              <span
                style={{
                  fontSize: "17px",
                  color: "rgb(69, 79, 91)",
                  fontWeight: "600",
                }}>
                Already have an Account?
              </span>
              <a
                href="/"
                className="pl-2"
                style={{
                  color: "#00476C !important",

                  fontSize: "17px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeWidgetState("LOGIN");
                }}>
                Login
              </a>
            </div>
          </div>

          <div
            id="lsq-form-modal"
            ref={(elem) => (window.lsqFormContainer = elem)}
            data-form-id="3ed636b0-44f7-11eb-b39f-122cab85b441"
            className="modal-v4 fullscreen external lsq-external-form-container">
            <div className="lsq-form-container-wrapper"></div>
            <div className="lsq-form-hidden-fields">
              <input
                id="lsq-authKey"
                name="lsq-authKey"
                type="hidden"
                value="cXJJcUttZ3BOVjEzNGhQSXZnblhkblVCYlQvSXJsWGEzQW1tZEIraW53VDN2eUlaS0owbkNZcUU2dXRoUk5hVXVhTjJ3clVPQStKYmtqOC9YM3FsT0Y1L3hCV2pDODFTZ2xpb01tZkM1bDdvVEM3RmpYdHhTTGRiWXlOdTRNSVVOZXNyYlJSKzZFQUZCbTZ2ZlFhQzJxc0F6VkZ6ME52Q0ZKckFzNEJsZS9hVENKaFJxTGp3aTZzeTAyRkVxck5mYVNMUEd4SERldEFlY1gwSGo3QkNVQT09"
              />
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value="https://portalapi-us11.leadsquared.com/api/Form"
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value="https://us11.leadsquared.com"
              />
              <input
                id="lsq-form-id-hidden"
                name="lsq-form-id-hidden"
                type="hidden"
                value="3ed636b0-44f7-11eb-b39f-122cab85b441"
              />
            </div>
          </div>
        </div>
        {/* <ExitPopup show={this.state.show} closeExitPop={this.closeExitPop} /> */}
      </>
    );
  }
}

export default withRouter(Registration);
