import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import LoadingScreen from "../../Loader/Loader";
import "../style.css";
// import Loader from ''
// import { instructionForSignNow } from "../../constants/common";
import {
  //   API_GetLeadOwnerDetails,
  API_UpdateActivity,
} from "../../../api/lead-activity-api";
// import AuthHandler from "../../utility/AuthHandler";

const BackgroundCheck = ({
  BackgroundCheck,
  setBackgroundCheck,
  activityId,
}) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateApplicationStatus = () => {
    setIsLoading(true);
    updateActivity("mx_Custom_2", "Background check in Process", activityId);
  };
  const updateActivity = (schemaName, status, activityId) => {
    // setIsLoading(true);
    // const { activityId } = this.state;
    const payload = {
      ProspectActivityId: activityId,
      //   ActivityEvent: config.ACTIVITY_EVENT,
      ActivityNote: "Student has Initiated Background check",
      Fields: [
        {
          SchemaName: schemaName,
          Value: status,
        },
      ],
    };

    API_UpdateActivity(payload)
      .then((res) => {
        // console.log("res", res);
        // setIsLoading(false);
        window.location.href = "/";
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="">
        <Modal
          show={BackgroundCheck}
          size="lg"
          onHide={() => setBackgroundCheck(false)}
          style={{ background: "rgba(0,0,0,0.6)" }}
        >
          <Modal.Header className="px-0">
            <div className="font-weight-bold helpTableHeading">
              {" "}
              Initiate Background check{" "}
            </div>
          </Modal.Header>

          <Modal.Body className="px-0 text-justify">
            <input
              type="checkbox"
              id="be1"
              className="be-checkbox mt-1 mr-2 "
              style={{ cursor: "pointer" }}
              checked={checkboxValue}
              onChange={() => {
                setCheckboxValue(!checkboxValue);
              }}
            />
            <span>
              In connection with your application for enrollment an inquiry will
              be made, limited to your criminal history and background. By
              checking the release box below, you are authorizing, without
              reservation, parties limited to law enforcement agencies, state
              agencies, institutions and private information bureaus or
              repositories to furnish any or all of the above mentioned
              information. Your authorization releases the institution from any
              and all liability for damages arising from the investigation and
              disclosure of the requested information. Further, it releases and
              discharges all liability from all companies, agencies, officials,
              officers, employees and other persons, who, in good faith provide
              to the Institute the above mentioned information as requested, in
              order to successfully complete a background investigation for your
              application for enrollment.
            </span>

            <div className="col-12 ">
              {checkboxValue && (
                <button
                  className="btn be-button ml-auto d-block"
                  onClick={updateApplicationStatus}
                >
                  Confirm
                </button>
              )}
            </div>
          </Modal.Body>

          {/* <Modal.Footer className="px-0 pb-0">
            <div className="container-fluid">
              <div className="row">
                <button
                  className="btn ml-auto helpTableCloseButton"
                  //   onClick={() => setBackgroundCheck(false)}
                >
                  Confirm
                </button>
                <button
                  className="btn ml-auto helpTableCloseButton"
                  onClick={() => setBackgroundCheck(false)}
                >
                  Close
                </button> *
              </div>
            </div>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};

export default BackgroundCheck;

