import React, { useEffect, useState } from "react";
// import Loader from "react-loader-spinner";
import { BallTriangle } from 'react-loader-spinner';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style.css";

const LoadingScreen = ({
  text = "Application is loading, Please wait..",
  timer = 1000,
  isLoaded = false,
  defaultTimer = false,
}) => {
  // 5 seconds
  const [completed, setCompleted] = useState(0);
  const extendedLoader4EA = localStorage.getItem("PCI_E_SIGNED");
  extendedLoader4EA &&  (text =  "We are fetching the next instructions for you to complete, Please wait.." ) // prettier-ignore
  useEffect(() => {
    if (extendedLoader4EA) {
      defaultTimer = true;
      timer = 59_000;

      // isLoaded = false;
    //   console.log("in,", [defaultTimer, timer, isLoaded, text]);
    }
    const recuringTimer = !defaultTimer ? 100 : 900;
    // console.log("recurring timer", recuringTimer);
    const interval =  setInterval( () => setCompleted(prev => {
      const randomNumber = !defaultTimer ? Math.floor(Math.random(5,10) * 10) : Math.floor(Math.random() * 3) + 1  

      
      // console.log("random",randomNumber)
      const newVal = Math.min(prev + randomNumber,100);
     
      // console.log("newVal",newVal);
      if(newVal === 100 || isLoaded){
        // console.log("terminated interval")
        clearInterval(interval);
        
      }
      return newVal;
    }), recuringTimer ); //prettier-ignore
    if (defaultTimer) {
      setTimeout(() => {
        //prettier-ignore
        isLoaded = true; //prettier-ignore
        clearInterval(interval);
        // console.log("executed timer"); //prettier-ignore
      }, timer);
    }
  }, []);

  return (
    <div className="loader-screen">
      <BallTriangle type="BallTriangle" color="#ffffff" height={100} width={100} />
      <p className="loader-text">
        {text} <span> {completed}% </span>
      </p>
    </div>
  );
};

export default LoadingScreen;





