var config = {
  AuthKey:
    "VUlQcHdRaW81RWJ3VmZMSDUyUXZ3bEdOaW1sVTB5Wk1SOWt6bGRWOEU3VFBPZU9IdEZzWmowK2p6WWdoM3Z6Q29QSmlTUlY2N3IrV3lIalhWK2FNK05GY2phSW1kSDFJTzRkMXpOMkEvR0ZLeE1DNUI4RjJXSlp3RkZoMFVwUUVXNnpwL0gzSFhPSzZVUXBtaWJHWjgxdTZCZnM4SkUyT1FURFlneGVJQnU5RXdNU2cvSXdLYjM3V2k5ZmRCUEREd0ZOZzF6NEZTbU1aaXc2L1FMa1F3UT09",

  portalBaseURL: "https://portalapi-us11.leadsquared.com/",
  lsqFormBaseURL: "https://us11.leadsquared.com",
  lsqFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r11/js/lsq.form.js?v=64.0.3.1",

  lsqRegistrationFormId: "3ed636b0-44f7-11eb-b39f-122cab85b441",
  lsqUploadDocumentFormId: "c1135f92-ca38-473d-935d-0e1d84596d64",

  // lsqFormId: "d24ff5a7-92d9-11eb-aa45-120cab83a541",
  lsqFormId: "8338972a-33ee-11eb-b39f-122cab85b441",
  lsqFormProcessId: "ce5065f0-0e11-11eb-972f-023400dba22c",
  lsqAppointmentProcessId: "959ab73e-ab0d-43f3-8936-1383f9774cc7",
  lsqFormProfileId: "c76247ec-80be-11eb-b39f-122cab85b441", //edit profile form
  lsqApplicationFeeFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r11/js/lsq.form.js?v=64.0.3.1",
  lsqApplicationFeeFormId: "7a22a503-abf0-11ea-bf5b-0a22ff7e4b86",
  iframeURL:
    "https://application.nimsuniversity.org/iframe/src/application-form-preview.html",

  ACTIVITY_EVENT: 105,
  PAYMENT_WAIVED: true,
  MASSACHUSETTS_STATE: "MA", // massachusetts State alias
  PDF_EXPORT_NAME: "PCI-",
  TEAS_TEST_FOR_PROGRAMS: {
    "Practical Nursing": "PN",
    "Respiratory Therapy": "RT",
    "Veterinary Technician": "VT",
  },
  CHECK_FOR_NOT_WONDERLINK: ["Chicopee", "Worcester", "Brockton", "New London"],
  CHECK_FOR_WONDERLINK: ["Rocky Hill", "Hamden", "Bridgeport", "Waterbury"],
  CHECK_FOR_WONDERLINK_TEAS_BOTH: {
    "Practical Nursing": [
      "Enfield",
      "Hamden",
      "Rocky Hill",
      "Stratford",
      "Waterbury",
      "Bridgeport",
    ],
    "Respiratory Therapy": ["Altoona"],
    "Veterinary Technician": ["York"],
  },
  // BACKGROUND_CHECK: ["hamden", "brockton"],
  BACKGROUND_CHECK: {
    "Automotive Technology": ["Hamden"],
    "Electrical Technology": ["Hamden"],
    "Practical Nursing": [
      "Enfield",
      "Hamden",
      "Rocky Hill",
      "Stratford",
      "Waterbury",
      "Bridgeport",
    ],
    "Computer and Data Management": ["York"],
    "Respiratory Therapy": ["Altoona"],
    "Criminal Justice and First Response": "Lancaster",
  },
  EA_MAP: {
    //EA_MAP =  enrollment agreement map
    /**
     * @var STATES these are the states falls under MASSACHUSETTS state.
     * @note all values inside states array must be lowercase
     *  */
    STATES: ["brockton", "chicopee", "worcester"], // these states
    /**
     * @var COURSES
     * @NOTE course array must contain the same  name as here and in the portal.
     */

    // COURSES: {
    //   "Enrollment Agreement 2023-MA Auto.pdf": ["Automotive Technology"],
    //   "Enrollment Agreement 2023-MA CNT.pdf": ["Computer & Network Technology"],
    //   "Enrollment Agreement 2023-MA CADD.pdf": [
    //     "Computer Aided Drafting & Design",
    //   ],
    //   "Enrollment Agreement 2023-MA CICRE.pdf": [
    //     "Career industrial, Commercial and Residential Electrician",
    //   ],
    //   "Enrollment Agreement 2023-MA HVACR.pdf": ["HVACR"],
    //   "Enrollment Agreement 2023-MA MA.pdf": ["Medical Assisting"],
    //   "Enrollment Agreement 2023-MA DA.pdf": ["Dental Assisting"],
    //   // "Enrollment Agreement 2022-04-19.pdf": ["Welding"],
    // },

    // Changes May 2024 kalyan.varichitty@leadsquared.com

    COURSES: {
      "Mass EA-EAA_AUTO_572024.pdf": ["Automotive Technology"],
      "Mass EA-EAA_CNT_52724.pdf": ["Computer & Network Technology"],
      "Mass EA-EAA_CADD_52724.pdf": [
        "Computer Aided Drafting & Design",
      ],
      "Mass EA-EAA_CICRE 71524.pdf": [
        "Career industrial, Commercial and Residential Electrician",
      ],
      "Mass EA-EAA_HVACR_52724.pdf": ["HVACR"],
      "Mass EA-EAA_MA_52724.pdf": ["Medical Assisting"],
      "Mass EA-EAA_ET_52724.pdf": ["Electrical Technology"],
      "Mass EA-EAA_DA_52724.pdf": ["Dental Assisting"],
      // "Enrollment Agreement 2022-04-19.pdf": ["Welding"],
    },


    // changes 28 Nov 2022
    // COURSES: {
    //   "Enrollment Agreement 2022-06-01 Auto MA.pdf": ["Automotive Technology"],
    //   "Enrollment Agreement 2022-06-01 CNT MA.pdf": [
    //     "Computer & Network Technology",
    //   ],
    //   "Enrollment Agreement 2022-06-01 CADD MA.pdf": [
    //     "Computer Aided Drafting & Design",
    //   ],
    //   "Enrollment Agreement 2022-08-01 MA CICRE.pdf": [
    //     "Career industrial, Commercial and Residential Electrician",
    //   ],
    //   // "Enrollment Agreement CICRE 2021-11-08 MA.pdf": [
    //   //   "Career industrial, Commercial and Residential Electrician",
    //   // ],
    //   "Enrollment Agreement 2022-06-01 HVACR MA.pdf": ["HVACR"],
    //   "Enrollment Agreement 2022-06-01 Medical MA.pdf": ["Medical Assisting"],
    //   "Enrollment Agreement 2022-06-01 Dental MA.pdf": ["Dental Assisting"],
    //   // "Enrollment Agreement 2022-04-19.pdf": ["Welding"],
    // },
  },
};

export default config;
