import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import AppLogo from "../../images/logo@2x.png";
import UserProfileContainer from "../../containers/UserProfileContainer";
import LogoutContainer from "../../containers/LogoutContainer";
import { Link } from "react-router-dom";
import "./style.css";
import config from "../../config";
import {
  API_GetLeadActivity,
  API_ActivityDetailsById
} from "../../api/lead-activity-api";
import AuthHandler from "../../utility/AuthHandler";
const Header = (props) => {

  const [educationFundingStatus, setEducationFundingStatus] = useState("");

  useEffect(() => {
    API_GetLeadActivity({
      leadId: AuthHandler.GetLeadId(),
      activityEvent: config.ACTIVITY_EVENT,
    })
      .then(function (response) {
        const application = response.data.message.ProspectActivities[0];
        // if(application && application.Id){
        //   setActivityId(application.Id)
        // }

        API_ActivityDetailsById({ activityId: application.Id })
          .then((res) => {
            const activityDetails = res.data.message;
            setEducationFundingStatus(activityDetails.Fields[5].Value)
          })
          .catch((error) => {
            console.log(error, "activity API");
          });
      })
  }, [])
  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top">
        <div className="container">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Brand href="/" className="mr-auto ml-auto">
            <img
              src={AppLogo}
              alt="PCI/YTI Career Insitute"
              className="img-fluid my-3"
            />
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <div className="nav-item d-none d-lg-block">
                <a
                  href="/"
                  style={{
                    // borderBottom: "4px solid #00476C",
                    paddingBottom: "27px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    position: "absolute",
                    top: "30px",
                    left: "50%",
                  }}
                  className="dashboardHeaderText"
                >
                  <i className="fa fa-home pr-2"></i>Dashboard
                </a>
              </div>
            </Nav>
            <Nav className="ml-auto">
              <div className="nav-item d-lg-block d-none mt-2">
                <UserProfileContainer />
              </div>
              <div className="nav-item d-block d-lg-none my-2">
                <Link to="/">Dashboard</Link>
              </div>
              <div className="nav-item d-block d-lg-none my-2">
                <Link to="/profile">Edit Profile</Link>
              </div>
              {
              educationFundingStatus === "" || educationFundingStatus === "Application Received and Entered" ?
               null
                :
                <li className="nav-item d-block d-lg-none my-2">
                <a href="https://porterchester.edu/schedule" target="_blank">Book FA Appointment</a>
              </li>
              }
              <div className="nav-item d-block d-lg-none my-2">
                <LogoutContainer />
              </div>
            </Nav>
            
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
