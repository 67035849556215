import React from "react";

const LandingBanner = ({ data }) => {
  return (
    <>
      <section className="landingMobileBanner">
        <img
          src={data.desktopBannerImage.path}
          className="d-none d-lg-block img-fluid"
        />
      </section>

      <section>
        <img
          src={data.mobileBannerImage.path}
          className="d-block d-lg-none img-fluid"
          style={{
            width: "100%",
          }}
        />
        {/* <div
          style={{
            fontFamily: "Times New Roman",
            fontSize: "28px",
            color: "#06224A",
            textAlign: "center",
            marginTop: "15px",
          }}>
          Admissions Portal
        </div> */}
      </section>
    </>
  );
};

export default LandingBanner;
