import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LeadDetails } from "../actions/index";
import UserProfile from "../components/UserProfile/UserProfile";
import AuthHandler from "../utility/AuthHandler";
const mapStateToProps = (state) => {
  return {
    authSession: state.authSessionInfo,
    leadDetails: state.leadDetails,
    user: AuthHandler.getUser(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLeadDetails: (leadDetails) => {
      return dispatch(LeadDetails.update(leadDetails));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
