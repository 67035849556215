import axios from "axios";
import AuthHandler from "../utility/AuthHandler";
import { PORTAL_API } from "../constants/endpoint";

let portalAPIInstance = axios.create({
  baseURL: PORTAL_API.BASE,
  timeout: 60000,
});

portalAPIInstance.interceptors.request.use(
  (config) => {
    if (AuthHandler.IsAuthSessionInfoCookiePresent()) {
      return config;
    } else {
      window.location.href = "/";
      throw new axios.Cancel("Operation Cancelled");
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

function API_Register(payload) {
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.REGISTER,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

function API_otpGenerate(payload) {
  // console.log(payload, "Generate Mobile Number");
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.OTP_GENERATE,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

function API_otpVerify(payload) {
  // console.log(payload, "Generated Mobile Number");
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.OTP_VERIFY,
    data: {
      Otp: payload.otp,
      FieldContent: payload.phone,
      Code: payload.code,
    },
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

const API_Login = (payload) => {
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.SIGN_IN,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
};

function API_RequestResetPasswordLink(payload) {
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.FORGOT_PASSWORD,
    data: payload,
    headers: { Authorization: AuthHandler.GetAuthKey() },
  });
}

const API_ResetPassword = (payload) => {
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.RESET_PASSWORD,
    data: payload,
    headers: { Authorization: AuthHandler.GetConfiguredAuthKey() },
  });
};

const API_Logout = (payload) => {
  return portalAPIInstance({
    method: "post",
    url: PORTAL_API.ENDPOINTS.SIGN_OUT,
    data: payload,
    headers: { Authorization: AuthHandler.GetConfiguredAuthKey() },
  });
};

export {
  API_Register,
  API_otpGenerate,
  API_otpVerify,
  API_Login,
  API_RequestResetPasswordLink,
  API_ResetPassword,
  API_Logout,
};
