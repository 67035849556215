import React from "react";
import { Modal } from "react-bootstrap";

const CheckInProgress = ({ checkInProgress, setCheckInProgress }) => {
  return (
    <>
      <Modal
        show={checkInProgress}
        size="md"
        onHide={() => setCheckInProgress(false)}
        style={{ background: "rgba(0,0,0,0.6)" }}
      >
        <Modal.Body className="px-0 text-justify">
          <span>
            Background check is in progress, please wait for the results.
          </span>
          <button
            className="btn be-button ml-auto d-block"
            onClick={() => setCheckInProgress(false)}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckInProgress;
