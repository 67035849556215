import React from "react";
import ApplicationHistoryContainer from "../../containers/ApplicationHistoryContainer";

const Dashboard = () => {
  return (
    <>
      <ApplicationHistoryContainer />
    </>
  );
};

export default Dashboard;
