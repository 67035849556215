import React from "react";
import { useState } from "react";
import LoginFormContainer from "../../containers/LoginFormContainer";
import RegisterContainer from "../../containers/RegisterContainer";
import ForgotPasswordForm from "../PreLoginForms/ForgotPasswordForm";

const SigninWidget = ({ state, logo }) => {
  const [widgetState, setWidgetState] = useState(state);
  const changeWidgetState = (widgetState) => {
    setWidgetState(widgetState);
  };

  const renderForm = (state) => {
    if (
      state === "REGISTER" ||
      state === "SIGNIN" ||
      state === "LOGIN" ||
      state === "FORGOTPASSWORD"
    ) {
      return (
        <div>
          <div className={state === "REGISTER" ? "d-block" : "d-none"}>
            <RegisterContainer changeWidgetState={changeWidgetState} />
          </div>
          <div
            className={
              state === "SIGNIN" || state === "LOGIN" ? "d-block" : "d-none"
            }
          >
            <LoginFormContainer changeWidgetState={changeWidgetState} />
          </div>
          <div className={state === "FORGOTPASSWORD" ? "d-block" : "d-none"}>
            <ForgotPasswordForm changeWidgetState={changeWidgetState} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <RegisterContainer changeWidgetState={changeWidgetState} />
        </div>
      );
    }
  };

  return (
    <>
      {widgetState === "REGISTER" ||
      widgetState === "LOGIN" ||
      widgetState === "SIGNIN" ? (
        <div className="widget-mobile-navigation">
          <ul>
            <li>
              <button
                className={`${widgetState === "REGISTER" && "active"}`}
                onClick={() => changeWidgetState("REGISTER")}
              >
                Sign Up
              </button>
            </li>
            <li>
              <button
                className={`${
                  (widgetState === "LOGIN" || widgetState === "SIGNIN") &&
                  "active"
                }`}
                onClick={() => changeWidgetState("LOGIN")}
              >
                Login
              </button>
            </li>
          </ul>
        </div>
      ) : null}
      <div className="form-container">{renderForm(widgetState)}</div>
    </>
  );
};

export default SigninWidget;
