export default {
  Brockton: {
    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=2791fddd-f390-4f8e-812c-c0f9a1d495fb",

    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=a8a79daa-41b9-4a5e-bccf-f1551f2a71d4",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=c3c83a06-65cf-49d1-b0b5-b15cc089b1e4",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=d830ab51-7d50-4a64-b1e7-9c08880622eb",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=85ffe112-bdd5-45a6-9507-b4568c61e228",

    "Career industrial, Commercial and Residential Electrician":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=9fd67831-c1ca-45be-a3f5-1727571eff11",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=24bcb7fb-9995-4355-bdc7-cad71474c958",
  },

  Bridgeport: {
    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=f0a4e913-70d5-4551-9a99-9b3925f884fe",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=85e5df05-df39-449c-bc99-623d8ae5483d",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=b2985367-5fee-462a-a1ad-7bc1cf2d5089",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=45ac253b-f04f-49d2-95c9-f84d854e1d08",

    "Career industrial, Commercial and Residential Electrician":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=3926e576-49f9-40b7-8c95-5fe61d632ffd",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=3926e576-49f9-40b7-8c95-5fe61d632ffd",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=b3b6c322-f943-43e2-ac8e-7086f80cf9e6",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=b64dbf4c-ea75-4a1a-9bef-0c2d7977f12b",

    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=43f6d4ca-1248-49d4-9937-3981d921b732",
  },

  Chicopee: {
    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=4237e297-f31b-4d85-92da-669929796930",

    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=7e74ec8a-e46c-44d7-a2c4-f5b20a2cd3d0",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=1ebd9f97-9130-463e-b1c4-e297f5863c7d",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=8e8bae8b-bce7-4132-9b61-db5bb65740a5",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=69a28274-8a27-4434-b1c7-298d6c9a4153",

    "Career industrial, Commercial and Residential Electrician":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=9fd67831-c1ca-45be-a3f5-1727571eff11",
    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=042d8f5e-5de5-4cb2-8cbc-603e74e0e6af",
  },

  Worcester: {
    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=6ae99c7a-a6a0-4fcc-ac18-897e7a4e20c7",

    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=04f6f749-752a-4785-8bd0-423c2e572412",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=c91290c8-2435-4277-97ab-4659f25f5a1d",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=c9dbed12-77bc-4c8b-b9f9-d6ada6cc4a8f",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=5599b7e7-6cb9-43ac-bf57-bbe5ee88f22f",

    "Career industrial, Commercial and Residential Electrician":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=9fd67831-c1ca-45be-a3f5-1727571eff11",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=b8d40bc8-7cdd-437f-b525-4fc8eee926f7",
  },

  Enfield: {
    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=09c0921a-694b-42ce-bff4-185c5a933de7",
  },

  Hamden: {
    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=1ca38f27-14d7-4428-9bd7-56cd19b70fbc",

    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=94e70bf3-8cc8-4ce8-afa4-044a7d167989",

    "Low Voltage Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e9f7a9ce-0dae-42c6-8319-4559bb66b468",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=0c033d27-bd1b-4dad-abd4-8b5d8a3d9e67",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=00d73506-b70d-4643-9350-aceadb90d502",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=c4318bba-6741-42ab-a456-6e6ae2447503",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=fa6e9dac-f02a-426a-a49e-5b0e43ff7226",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=fe41bee2-6f84-4273-a51f-22ccb7efe35d",

    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=34c0e322-3ca8-4366-8dd2-afdd063468d6",
  },

  "Rocky Hill": {
    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=970fc2f9-d98b-4ca6-af37-adcb4f1a54d9",

    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=0a2e1139-f012-4ad3-af1c-dc8a7c38c557",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=3b2261e5-73be-46e8-82af-5afde5a2f2ef",

    "Low Voltage Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=f73e2df0-cf84-489d-a160-a8fb03cbe0c0",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=9f70d892-d829-45b4-b50f-31d8459a85ea",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=ef5f066d-3292-49c9-b56d-9ffbaedcdab5",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=3360f2a1-c4c1-4f8e-8cfc-b747c56c461b",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=c0a600dd-8c33-497d-ab64-982162332d47",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=29486a18-b4ca-4962-b7d0-796dd705ecc3",

    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=fc1978a3-cd37-49df-977f-3debf2fb1bfc",

    Cosmetology:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=dfa9f9b8-428a-41b9-89d5-201d05dd9362",
  },

  "New London": {
    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=4f24a229-847c-4da9-b402-dadddd1973c2",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=63b05563-d48f-48aa-8f06-75a81e77e8fc	",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=da41a393-95d9-4cb6-a6f0-c89994f37430",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=358b57f5-826c-4606-a726-3e32d750a0de",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=4ee9c4df-c4ef-4774-ac35-0ce712316f67",
  },

  Stratford: {
    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=8f8c5fda-421e-4021-bb2c-0dae4f9f24a7",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=4d87b393-28ed-482b-9db2-e5a5c32de831",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=ffcac955-b854-4a4f-8816-0775ca3dd794",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=f7b34f43-0610-4e86-9873-5bcface6dd93",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=7e3d7c8d-f27f-4d07-aace-e4ff53dd3cba",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=ed9a0108-740a-4bfd-b61a-7b5cf150f71d",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=87ed27d5-096b-4517-90f9-27541b5c94b8",

    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=81a7b937-64e7-4381-be6e-a24cc5af915b",
  },

  Waterbury: {
    "Automotive Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=d3f6e480-569b-4433-90b8-3380d985b32a",

    "Computer Aided Drafting & Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e61e3202-6075-49ed-9d9e-07acbfa8709c",

    "Computer & Network Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=5b224476-61ae-4ded-bb87-55436103023c	",

    "Low Voltage Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=2e638152-4b3d-46fa-96b6-1cd3690ded56",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=0056e03b-3eb5-4fcf-8bc5-202a9ba45cf4",

    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=9bc2cf4d-755a-47b2-a2b2-0e7e2b00a6a6",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=93be135a-a8fd-4555-a60c-917d67cdcc7e",

    "Medical Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=1444e447-5e6f-4cef-b500-b33e09f2592d",

    Plumbing:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=121b94b2-cd25-4366-9076-8bee96bc1a19",

    "Practical Nursing":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=202c6954-6b70-454d-97ed-0878f4c74afd",
  },

  Altoona: {
    "Respiratory Therapy":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e149ecfb-0a8c-42fb-8640-f2cf787f3423",
  },

  York: {
    "Business Administration - Business Operations Management":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e18b9fae-07f3-47cf-9113-e3b6ccb64ac2",
    HVACR:
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=5dd14ef9-3763-47e2-9e44-74d6d39ec756",

    "Computer Aided Drafting and Design":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e18b9fae-07f3-47cf-9113-e3b6ccb64ac2",

    "Computer and Data Management":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=3b9ce72a-d506-4da3-b5f3-fe6b918686ec",

    "Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=b0bb34a0-326e-4ebb-aca5-676cdeeb1930",

    "Electrical Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=40f2c7ab-0991-4b22-98c7-edbee5e1e97a",

    "Electronics Engineering Technology":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=a708453a-3874-4cb9-97d5-4f9fe89cb86b",

    "Medical Assistant":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=f369d193-f894-448b-b760-9b2f722b3b13",

    "Veterinary Technician":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=cd120374-20ad-47c7-b6b3-86cc41090667",
  },

  Lancaster: {
    "Criminal Justice and First Response":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=2ceee874-17d7-41f6-87c5-7e0f926424c3",

    "Culinary Arts/Restaurant Management":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=8148dc34-785a-4d0e-aa59-aad783c258d1",

    "Expanded Functions Dental Assisting":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=ef948f53-e7c1-4ab7-a9c7-4dd6acf1c9cb",

    "Health Information Management":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=1ee2c935-f522-4235-9806-efb1be34e969",

    "Medical Billing and Coding":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=297bd2dd-a971-4ba8-8ccd-35eaa607a760",

    "Pastry Arts":
      "https://testing.wonderliconline.com/WebPages/Links.aspx?lid=e5adbf24-9d90-4a07-8537-576d5039beb3",
  },
};
