import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Logout } from "../actions/index";
import LogoutComponent from "../components/UserProfile/Logout";

const mapStateToProps = state => {
  return {
    authSession: state.authSessionInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestLogout: () => {
      dispatch(Logout.requestLogout());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutComponent)
);
