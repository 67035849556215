import logo from "../../images/logo@2x.png";
// import mobileBanner from "../../images/mobileBanner.webp";
// import desktopBanner from "../../images/desktopBanner.webp";

import mobileBanner from "../../images/mobileBanner1.webp";
import desktopBanner from "../../images/desktopBanner1.webp";

const appLogo = {
  path: logo,
  alt: "PCI University",
  style: {
    height: "22px",
    width: "214px",
  },
  class: "img-fluid",
};

const mobileBannerImage = {
  path: mobileBanner,
  alt: "Landing Banner",
};

const desktopBannerImage = {
  path: desktopBanner,
  alt: "Landing Banner",
};

const landingData = { desktopBannerImage, mobileBannerImage };

export { landingData, appLogo };
