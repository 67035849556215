/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */

import React from "react";

const LogoutComponent = (props) => {
  const signout = () => {
    /* 
    replaced sessionStorage with localStorage so comment this line
sessionStorage.removeItem("LSQ_AG_INITIATE_TIME");
    sessionStorage.removeItem("LSQ_EA_INITIATE_TIME");
    sessionStorage.removeItem("LSQ_PCW_INITIATE_TIME");
    sessionStorage.removeItem("LSQ_DOCUMENT_STATUS");
    sessionStorage.removeItem("LSQ_DOCUMENT_LINKS"); */
    sessionStorage.removeItem(
      "LSQ_AG_INITIATE_TIME_INSIDE" + props.authSession.leadId
    );
    sessionStorage.removeItem(
      "LSQ_PCW_INITIATE_TIME_INSIDE" + props.authSession.leadId
    );

    localStorage.removeItem(`LeadOwnerName_${props.authSession.leadId}`);
    localStorage.removeItem(`LeadOwnerEmail_${props.authSession.leadId}`);
    localStorage.removeItem(`LeadOwnerMobile_${props.authSession.leadId}`);
    sessionStorage.removeItem("LSQ_DOCUMENT_LINKS" + props.authSession.leadId);
    localStorage.removeItem("_PCIYTI_LEAD");
    props.requestLogout();
    props.history.push("/Home");
  };

  return (
    <a
      className="popover-item"
      href="/"
      onClick={(e) => {
        e.preventDefault();
        signout();
      }}
    >
      Logout
    </a>
  );
};

export default LogoutComponent;
