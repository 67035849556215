// import BrandLogo from "../../src/images/PCI-YTI-logo@2x.png";

// const AppBranding = {
//   path: BrandLogo,
//   alt: "Porter and chester institute",
// };

const instructionForSignNow = `Please click on DONE after you have signed and Go to Dashboard to download the signed document.`;
const nameForStorage = (leadId) => "LSQ_CAMPUS_&_PROGRAM_" + leadId;
const SIGNNOW_SUCCESS_URL = window.location.origin + "/success.html";
const SIGNNOW_IFRAME_URL = `https://app.signnow.com/webapp/document/`;
export {
  instructionForSignNow,
  nameForStorage,
  SIGNNOW_SUCCESS_URL,
  SIGNNOW_IFRAME_URL,
};
