import React from "react";
import { withRouter } from "react-router";
import Header from "../Header";
import PoweredByLeadSquared from "../PoweredByLeadSquared";
import PostLoginRoute from "../../routes";
import "./style.css";

const LoggedInApp = () => {
  return (
    <div className="logged-in-wrapper">
      <Header />
      <section className="main-wrapper">
        <PostLoginRoute />
      </section>
      <PoweredByLeadSquared />
    </div>
  );
};

export default withRouter(LoggedInApp);
