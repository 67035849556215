/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_Login } from "../../api/signing-request";
import { API_GetLeadsById } from "../../api/lead-activity-api";

import AuthHandler from "../../utility/AuthHandler";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import { ERROR_MSG } from "../../constants/error";
import Input from "../Input";
import Button from "../Button";
import { /* landingData ,*/ appLogo } from "../LandingLayout/data";
// import moment from "moment";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const onhandleSubmit = () => {
    setIsLoading(true);
    API_Login({ EmailAddress: email, Password: password })
      .then(function (response) {
        API_GetLeadsById({
          leadId: response.data.LeadId,
        })
          .then(function (res) {
            const completeLeadDetails = res.data.message[0];

            const authSessionInfo = {
              isAuthenticated: true,
              leadId: response.data.LeadId,
              authKey: response.data.AuthKey,
              user: {
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
              },
            };

            if (completeLeadDetails) {
              const leadDetails = JSON.stringify({
                email: completeLeadDetails.EmailAddress,
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
                state: completeLeadDetails.mx_State,
                mx_Read_Important_Notice:
                  completeLeadDetails.mx_Read_Important_Notice,
              });
              sessionStorage.setItem("user-info", leadDetails);

              localStorage.setItem("_PCIYTI_LEAD", authSessionInfo.leadId);
              props.updateLeadDetails({
                email: completeLeadDetails.EmailAddress,
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
                state: completeLeadDetails.mx_State,
                mx_Read_Important_Notice:
                  completeLeadDetails.mx_Read_Important_Notice,
              });
            }

            AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
            props.updateLoginInfo(authSessionInfo);

            props.history.push("/Home");
            setIsLoading(false);
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      })
      .catch(function (error) {
        const errorMessage =
          error.response.data.ExceptionMessage &&
          error.response.data.ExceptionMessage.includes("{")
            ? ERROR_MSG.ALREADY_LOGIN
            : error.response.data.ExceptionMessage;
        setIsLoading(false);
        setFormMessage(errorMessage);
      });
  };

  return (
    <>
      <div className="logo-container pb-5 mb-5">
        <img src={appLogo.path} alt={appLogo.alt} style={appLogo.style} />
      </div>
      <div
        style={{
          fontFamily: "Times New Roman",
          fontSize: "28px",
          color: "#06224A",
          textAlign: "center",
          // marginTop: "15px",
        }}>
        Admissions Portal
      </div>
      <div
        className="form-footer pb-4"
        style={{ fontSize: "15px", color: "#454F5B" }}>
        Don't have an account?{" "}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            props.changeWidgetState("REGISTER");
          }}
          style={{
            fontSize: "16px",
            color: "#00476C !important",
            textDecoration: "underline",
          }}>
          <span className="font-weight-bold">Sign Up</span>
        </a>
      </div>

      <div className="form-skeleton mt-3">
        {formMessage && <p className="form-message"> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}>
          <Input
            type="email"
            handleChange={(value) => {
              setEmail(value);
            }}
            handleBlur={() => {}}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />

          <Input
            type="password"
            handleChange={(value) => {
              setPassword(value);
            }}
            handleBlur={() => {}}
            value={password}
            name="Password"
            error={false}
            label="Password"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.PASSWORD}
            sideLink={
              <div className="forgot-password">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    props.changeWidgetState("FORGOTPASSWORD");
                  }}
                  style={{ color: "#00476C !important" }}>
                  Forgot Password?
                </a>
              </div>
            }
          />
          <div className="form-group">
            <Button
              disabled={!email || !password}
              type="submit"
              className="btn-primary submit-button"
              text="Login"
              status={isLoading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;

