import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthSessionInfo } from "../actions/index";
import Layout from "../components/Layout";

function mapStateToProps(state) {
  // console.log(state, "layoutcontainer");
  return {
    authSession: state.authSessionInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isAuthenticated: function (isAuthenticated) {
      dispatch(AuthSessionInfo.isAuthenticated(isAuthenticated));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
