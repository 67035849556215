const PORTAL_API = {
  BASE: "https://portalapi-us11.leadsquared.com/",
  ENDPOINTS: {
    SIGN_IN: "api/Authentication/Signin",
    REGISTER: "api/Authentication/Register",
    FORGOT_PASSWORD: "api/Authentication/SecureForgotPassword",
    CHANGE_PASSWORD: "api/Settings/ChangePassword",
    SIGN_OUT: "api/Authentication/Signout",
    RESET_PASSWORD: "api/Authentication/ResetPassword",
    OTP_GENERATE: "/api/Form/SendOTP?",
    OTP_VERIFY: "/api/Form/VerifyOTP",
  },
};

const LSQ_API = {
  BASE: "https://api-us11.leadsquared.com/v2/",
  ENDPOINTS: {
    GET_LEAD_BY_ID: "LeadManagement.svc/Leads.GetById",
    GET_LEAD_ACTIVITY: "ProspectActivity.svc/Retrieve",
    CREATE_ACTIVITY: "ProspectActivity.svc/Create",
    UPDATE_ACTIVITY: "ProspectActivity.svc/CustomActivity/Update",
    GET_ACTIVITY_DETAILS_BY_ID: "ProspectActivity.svc/GetActivityDetails",
    UPDATE_LEAD: "LeadManagement.svc/Lead.Update",
    GET_LEAD_OWNER: "LeadManagement.svc/LeadOwner.Get"
  },
};

export { PORTAL_API, LSQ_API };
