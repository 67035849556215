import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../style.css";

// import TransparentLoader from "../../Loader/transparentLoader";

let VideoModal = ({ src, videoModal, setVideoModal }) => {
  return (
    <>
      <div className="">
        <Modal
          show={videoModal}
          onHide={() => true}
          dialogClassName="noticeModalWrapper"
          style={{ background: "rgba(0,0,0,0.6)" }}
        >
          <Modal.Header>
            <b>Watch video</b>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="390"
              src={src + "?rel=0"}
              title="YouTube video player"
              frameBorder="0"
              allow="encrypted-media;fullscreen;"
              allowFullScreen
            ></iframe>
          </Modal.Body>

          <Modal.Footer className="px-0 pb-0">
            <div className="container-fluid">
              <div className="row">
                <button
                  className="btn ml-auto helpTableCloseButton"
                  onClick={() => setVideoModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default VideoModal;
