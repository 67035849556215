/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_RequestResetPasswordLink } from "../../api/signing-request";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import Input from "../Input";
import Button from "../Button";
import { appLogo } from "../LandingLayout/data";

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const onhandleSubmit = () => {
    setIsLoading(true);
    setIsDisabled(true);
    API_RequestResetPasswordLink({
      EmailAddress: email,
    })
      .then(function (response) {
        setIsLoading(false);
        setFormMessage("An email with a link has been sent successfully");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsDisabled(false);
        setFormMessage(error.response.data.message.ExceptionMessage);
      });
  };

  return (
    <>
      <div className="logo-container pb-5 mb-5">
        <img src={appLogo.path} alt={appLogo.alt} style={appLogo.style} />
      </div>
      <div className="form-skeleton">
        <div className="form-title-wrapper">
          <h3>Forgot Password</h3>
          <p>Please enter your email, We will send a password reset link.</p>
        </div>
        {formMessage && <p className="form-message"> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="email"
            handleChange={(value) => {
              setEmail(value);
            }}
            handleBlur={() => {}}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />

          <div className="form-group">
            <Button
              disabled={email !== "" && isDisabled}
              type="submit"
              className="btn-primary submit-button"
              text="Send Password Reset Link"
              status={isLoading}
            />
          </div>
        </form>
        <div className="form-footer">
          {/* <div>
            <a
              href="javascript:void(0)"
              onClick={() => props.changeWidgetState("LOGIN")}
            >
              Sign In
            </a>
          </div> */}
          <div
            style={{
              fontSize: "15px",
              color: "rgb(69, 79, 91)",
              fontWeight: "600",
            }}
          >
            Already have an account?{" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                props.changeWidgetState("LOGIN");
              }}
              style={{
                color: "#00476C !important",
                textDecoration: "none",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                className="font-weight-bold"
                style={{ textDecoration: "underline" }}
              >
                Login
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
