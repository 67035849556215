import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Button = ({ disabled, type, className, text, status, handleClick }) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      onClick={handleClick}
    >
      {status ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
