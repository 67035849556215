import React, { useEffect, useState } from "react";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BallTriangle } from 'react-loader-spinner';
import "./style.css";

const TransparentLoader = ({
  text = "Application is loading, Please wait..",
  timer = 1000,
  isLoaded = false,
  defaultTimer = false,
}) => {
  // 5 seconds
  const [completed, setCompleted] = useState(0);
  useEffect(() => {
    const recuringTimer = !defaultTimer ? 100 : 500;
    const interval =  setInterval( () => setCompleted(prev => {
        const randomNumber = !defaultTimer ? Math.random(5,10) : Math.random(1,2);
        const newVal = Math.min(prev + Math.floor(randomNumber * 10),100);
       
        if(newVal === 100 || isLoaded){
          clearInterval(interval);
        }
        return newVal;
      }), recuringTimer ); //prettier-ignore
    defaultTimer &&  setTimeout(() => {//prettier-ignore
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isLoaded = true;//prettier-ignore
        // console.log("executed timer");//prettier-ignore
      }, timer); //prettier-ignore
    // return () => {}; //clearnup
  }, []);
  return (
    <div className="loader-screen2">
      <BallTriangle type="BallTriangle" color="#ffffff" height={100} width={100} />
      <p className="loader-text">
        {" "}
        {text} <span> {completed}% </span>{" "}
      </p>
    </div>
  );
};

export default TransparentLoader;
