import React from "react";
import LoggedInLayout from "../LoggedInLayout";
import LandingLayout from "../LandingLayout";

const Layout = (props) => {
  if (props.authSession.isAuthenticated) {
    return <LoggedInLayout />;
  } else {
    return <LandingLayout />;
  }
};

export default Layout;
