/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  API_GetLeadActivity,
  API_UpdateActivity,
  API_ActivityDetailsById,
  // API_GetLeadOwnerDetails,
  // API_GetLeadsById,
} from "../../api/lead-activity-api";
import LoadingScreen from "../Loader/Loader";
import { getSessionData } from "../../utility/common";
import DashboardBanner from "./DashboardBanner";
import ApplicationCard from "./ApplicationCard";
import ApplicationStages from "./ApplicationStages";
import NoticeModal from "./noticeModal";
// import HelpModal from "./helpModal";
import ContactCareerProgram from "../ContactCareerProgram";

// import { Link } from "react-router-dom";
import config from "../../config";
// import DetailCard from "./DetailCard";
import "./style.css";
import WonderlicTestLinks from "./WonderlicLinks";
// import AuthHandler from "../../utility/AuthHandler";
import { nameForStorage } from "../../constants/common";
import AuthHandler from "../../utility/AuthHandler";
import VideoModal from "./Modal/VideoModal";
import BackgroundCheck from "./Modal/BackgroundCheck";
import CheckInProgress from "./Modal/CheckInProgress";
// let stage = null; // to test change this to any status available
// let stage = "School Readiness Assessment in Review"; // to test change this to any status available

const getMassSpecifFormLink = (status, activityId = null, getIndex = false) => {
  // second param as null to be on same side
  const massLinks = {
    "blank enrolment pending": "/Home/BlankEnrollement/" + activityId,
    "ag program disclosure e sign pending":
      "/Home/AgProgram/disclosure/" + activityId,
    "program cost worksheet pending":
      "/Home/ProgramcostWorksheet/" + activityId,
  };
  if (getIndex) {
    return Object.keys(massLinks).indexOf(status) + 1;
  }
  return massLinks[status];
};

const ApplicationHistory = (props) => {
  // console.log("tore ================>", props);
  const [applicationData, setApplicationData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const userInfo = AuthHandler.getUser() || getSessionData("user-info");
  // console.log("u info", userInfo);
  const [doBackgroundCheck, setDoBackgroundCheck] = useState(false);
  const [stage, setStage] = useState(null);
  const [campus, setCampus] = useState(null);
  const [course, setCourse] = useState(null);
  const [videoModal, setVideoModal] = useState(false);
  const [iFrameYoutube, setIframeYoutube] = useState("");
  const [AGPdfUrl, setAGPdfUrl] = useState(null);
  const [PCWPdfUrl, setPCWPdfUrl] = useState(null);
  const [EAPdfUrl, setEAPdfUrl] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [checkInProgress, setCheckInProgress] = useState(false);
  /* const [leadOwnerDeatils, setLeadOwnerDetails] = useState({}); */

  const [shouldDownloadDocument, setShouldDownloadDocument] = useState({
    AG: false,
    PCW: false,
    EA: false,
  });
  // if (!userInfo) {
  //   props.requestLogout();
  //   props.history.push("/");
  // }

  const updateActivity = (schemaName, status, activityId) => {
    setIsLoading(true);
    // const { activityId } = this.state;
    const payload = {
      ProspectActivityId: activityId,
      ActivityEvent: config.ACTIVITY_EVENT,
      ActivityNote:
        "Application status changed from assessment to Review catalog pending",
      Fields: [
        {
          SchemaName: schemaName,
          Value: status,
        },
      ],
    };

    API_UpdateActivity(payload)
      .then((res) => {
        // console.log("res", res);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    setUserDetails(userInfo);

    // console.log("userInfo", userInfo);

    API_GetLeadActivity({
      leadId: props.authSession.leadId,
      activityEvent: config.ACTIVITY_EVENT,
    })
      .then(function (response) {
        // console.log(response.data, "Dashboard data");
        setApplicationData(
          response.data && response.data.message.ProspectActivities
        );
        const count = response.data && response.data.message.RecordCount;
        setCount(count);
        const application = response.data.message.ProspectActivities[0];
        // console.log("application 56", application);
        //generate token for access token for document
        if (application && application.Id) {
          setActivityId(application.Id);
        }
        const isAGChecked = localStorage.getItem(
          "LSQ_AG_INITIATE_TIME" + application?.RelatedProspectId
        );
        const currentUnixTIme = new Date().valueOf();
        const AG_isCompleted =
          (application?.ActivityFields?.mx_Custom_18 &&
            JSON.parse(application?.ActivityFields?.mx_Custom_18)
              ?.mx_CustomObject_1) ||
          false;
        setShouldDownloadDocument({
          ...shouldDownloadDocument,
          AG: AG_isCompleted,
        });
        // console.log("AG_isCompleted", AG_isCompleted);
        if (
          !AG_isCompleted &&
          application?.ActivityFields?.mx_Custom_12 &&
          (!isAGChecked || currentUnixTIme > parseInt(isAGChecked))
        ) {
          /* setIsLoading(true);
          API_SignnowAccessTokenLapp({
            access_token: application.ActivityFields?.mx_Custom_15, //access token
            document_id: application.ActivityFields?.mx_Custom_12, // document id
            type: "mx_Custom_15",
            activityID: application?.Id,
          })
            .then((s) => {
              localStorage.setItem(
                "LSQ_AG_INITIATE_TIME" + application?.RelatedProspectId,
                new Date().setHours(23, 59, 59, 59)
              );
              setIsLoading(false);
            })
            .catch(console.log); */
        }
        const isPCWChecked = localStorage.getItem(
          "LSQ_PCW_INITIATE_TIME" + application?.RelatedProspectId
        );

        const PCW_isCompleted =
          (application?.ActivityFields?.mx_Custom_19 &&
            JSON.parse(application.ActivityFields?.mx_Custom_19)
              ?.mx_CustomObject_1) ||
          false;
        // console.log("PCW_isCompleted", PCW_isCompleted)
        setShouldDownloadDocument({
          ...shouldDownloadDocument,
          PCW: PCW_isCompleted,
        });
        // console.log("PCW_isCompleted", PCW_isCompleted);
        if (
          !PCW_isCompleted &&
          application?.ActivityFields?.mx_Custom_13 &&
          (!isPCWChecked || currentUnixTIme > parseInt(isPCWChecked))
        ) {
          /*  setIsLoading(true);
          API_SignnowAccessTokenLapp({
            access_token: application.ActivityFields?.mx_Custom_16,
            document_id: application.ActivityFields?.mx_Custom_13,
            type: "mx_Custom_16",
            activityID: application.Id,
          })
            .then((s) => {
              localStorage.setItem(
                "LSQ_PCW_INITIATE_TIME" + application?.RelatedProspectId,
                new Date().setHours(23, 59, 59, 59)
              );
              setIsLoading(false);
            })
            .catch(console.log); */
        }

        const isEAChecked = localStorage.getItem(
          "LSQ_EA_INITIATE_TIME" + application?.RelatedProspectId
        );
        const EA_isCompleted =
          (application?.ActivityFields?.mx_Custom_20 &&
            JSON.parse(application.ActivityFields?.mx_Custom_20)
              ?.mx_CustomObject_1) ||
          false;
        // console.log("EA_isCompleted", EA_isCompleted);

        setShouldDownloadDocument({
          ...shouldDownloadDocument,
          EA: EA_isCompleted,
        });
        if (
          !EA_isCompleted &&
          application?.ActivityFields?.mx_Custom_14 &&
          (!isEAChecked || currentUnixTIme > parseInt(isEAChecked))
        ) {
          /* console.log("inside EA");
          setIsLoading(true);
          API_SignnowAccessTokenLapp({
            access_token: application.ActivityFields?.mx_Custom_17,
            document_id: application.ActivityFields?.mx_Custom_14,
            type: "mx_Custom_17",
            activityID: application.Id,
          })
            .then((s) => {
              localStorage.setItem(
                "LSQ_EA_INITIATE_TIME" + application?.RelatedProspectId,
                new Date().setHours(23, 59, 59, 59)
              );
              setIsLoading(false);
            })
            .catch(console.log); */
        }
        // console.log("shouldDownloadDocument", shouldDownloadDocument);
        const campus =
          application &&
          application.ActivityFields &&
          application.ActivityFields.mx_Custom_1 &&
          JSON.parse(application.ActivityFields.mx_Custom_1);

        const storageName = nameForStorage(props.authSession.leadId); // please dont the name
        localStorage.setItem(storageName, JSON.stringify(campus));
        setCampus(
          campus && campus.mx_CustomObject_1 && campus.mx_CustomObject_1
        );
        setCourse(campus?.mx_CustomObject_2);
        // console.log(
        //   "background check",
        //   campus,
        //   config.BACKGROUND_CHECK[campus?.mx_CustomObject_2]
        // );
        if (
          campus &&
          campus.mx_CustomObject_1 &&
          config.BACKGROUND_CHECK[campus.mx_CustomObject_2]?.indexOf(
            campus.mx_CustomObject_1
          ) > -1
        ) {
          setDoBackgroundCheck(true);
        }
        // console.log("application", application);
        const stage =
          (count > 0 &&
            application &&
            application.ActivityFields &&
            application.ActivityFields.mx_Custom_2) ||
          "";
        setStage(stage);
        // console.log("stage", stage);

        const applicationPaymentStatus =
          (application &&
            application.ActivityFields &&
            application.ActivityFields.mx_Custom_4) ||
          "";
        // console.log("applicationPaymentStatus", applicationPaymentStatus);
        setPaymentStatus(applicationPaymentStatus);
        // console.log(response.data.RecordCount);

        if (localStorage.getItem("PCI_E_SIGNED")) {
          setTimeout(() => {
            localStorage.removeItem("PCI_E_SIGNED");
            setIsLoading(false);
          }, 50_000);
        }
        // else {
        //   setIsLoading(false);
        // }
        if (application && application.Id) {
          API_ActivityDetailsById({ activityId: application.Id })
            .then((res) => {
              const activityDetails = res.data.message;
              const AGPdfUrl =
              activityDetails.Fields[20]?.CustomObjectFormProperties?.FieldProperties?.FormMetaData[1]?.FileURL; // prettier-ignore
              const PCWPdfUrl =
              activityDetails.Fields[21]?.CustomObjectFormProperties?.FieldProperties?.FormMetaData[1]?.FileURL; // prettier-ignore
              const EAPdfUrl =
              activityDetails.Fields[22]?.CustomObjectFormProperties?.FieldProperties?.FormMetaData[1]?.FileURL; // prettier-ignore

              AGPdfUrl && setAGPdfUrl(AGPdfUrl);
              PCWPdfUrl && setPCWPdfUrl(PCWPdfUrl);
              EAPdfUrl && setEAPdfUrl(EAPdfUrl);
              setIsLoading(false);
              // console.log(AGPdfUrl, "=--- ",PCWPdfUrl, "---", EAPdfUrl)
            })
            .catch((error) => {
              console.log(error, "activity API");
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        // alert("Something Went Wrong!!!");
        // window.location.href = "/";
      });
  }, []);

  /*   // For Fetching Lead Owner Details
  useEffect(() => {
    let leadId = AuthHandler.GetLeadId();

    let leadOwnerName_key = `LeadOwnerName_${leadId}`;
    let leadOwnerEmail_key = `LeadOwnerEmail_${leadId}`;
    let leadOwnerMobile_key = `LeadOwnerMobile_${leadId}`;

    let name = localStorage.getItem(leadOwnerName_key);
    let email = localStorage.getItem(leadOwnerEmail_key);
    let mobile = localStorage.getItem(leadOwnerMobile_key);

    if (email === null) {
      API_GetLeadOwnerDetails().then((res) => {
        console.log("========= GetLeadOwnerDetails API Called =======");
        let data = res?.data?.[0];
        let ownerName = data?.FirstName ?? "-";
        let ownerEmail = data?.EmailAddress ?? "-";
        let ownerMobile = data?.AssociatedPhoneNumbers ?? "-";

        localStorage.setItem(leadOwnerName_key, ownerName);
        localStorage.setItem(leadOwnerEmail_key, ownerEmail);
        localStorage.setItem(leadOwnerMobile_key, ownerMobile);

        setLeadOwnerDetails({
          ...leadOwnerDeatils,
          name: ownerName,
          mobile: ownerMobile,
          email: ownerEmail,
        });
      });
    } else {
      setLeadOwnerDetails({
        ...leadOwnerDeatils,
        name,
        mobile,
        email,
      });
    }
  }, []); */

  const renderApplication = (application, index, userInfo) => {
    const activityId = application.Id ? application.Id : "";
    // console.log("Activity Id",activityId)
    const lastUpdate = application.ModifiedOn ? application.ModifiedOn : "";
    const activity = application.ActivityFields && application.ActivityFields;
    const applicationId =
      application.ActivityFields && application.ActivityFields.mx_Custom_5
        ? application.ActivityFields.mx_Custom_5
        : "";
    const educationalFunding =
      application &&
      application.ActivityFields &&
      application.ActivityFields.mx_Custom_3;
    // const applicationStatus =
    //   application.ActivityFields && application.ActivityFields.mx_Custom_2
    //     ? application.ActivityFields.mx_Custom_2
    //     : "";
    const applicationStatus = stage;

    const applicationPaymentStatus = paymentStatus;

    // console.log(applicationPaymentStatus, "applicationPaymentStatus");

    if (
      applicationStatus === "" ||
      (applicationStatus.toLowerCase() === "application pending" &&
        ["", "fee pending"].indexOf(applicationPaymentStatus.toLowerCase()) !==
          -1)
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus=""
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (
      ["application in review", "application saved"].indexOf(
        applicationStatus.toLowerCase()
      ) !== -1 &&
      ["", "fee pending"].indexOf(applicationPaymentStatus.toLowerCase()) !== -1 // inital payment is empty so this will always be true in this cond only
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: config.PAYMENT_WAIVED
              ? `/Home/ApplicationFeeSuccess/${activityId}`
              : `/Home/ApplicationFeeForm/${activityId}`,
            text: "Pay Application Fee",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      [
        "application saved",
        "application approved",
        "application in review",
      ].indexOf(applicationStatus.toLowerCase()) !== -1 &&
      ["fee paid", "fee waived"].indexOf(paymentStatus.toLowerCase()) !== -1
    ) {
      // console.log("activity", activity);
      // console.log("activity.mx_Custom_32", activity.mx_Custom_32);
      // if (
      //   activity.mx_Custom_1 &&
      //   config.CHECK_FOR_NOT_WONDERLINK.includes(
      //     JSON.parse(activity.mx_Custom_1).mx_CustomObject_1
      //   )
      // ) {
      //   // this case is for non-wonderlink
      //   // setStage("Review Catalog Pending"); // this is has been done for application stages.
      //   // updateActivity("mx_Custom_2", "Review Catalog Pending", activityId);
      //   // setStage("Application in Review"); // this is has been done for application stages.
      //   console.log("Non-Wonderlink Case 1");
      //   return (
      //     <ApplicationCard
      //       setIframeYoutube={setIframeYoutube}
      //       setVideoModal={setVideoModal}
      //       shouldDownloadDocument={shouldDownloadDocument}
      //       educationalFunding={educationalFunding}
      //       applicationId={applicationId}
      //       user={userInfo}
      //       application={application}
      //       applicationStatus={applicationStatus}
      //       paymentStatus={paymentStatus}
      //       modifiedOn={lastUpdate}
      //       showTeas={true}
      //       applicationCTA={{
      //         link: `/Home/ApplicationForm/Preview/${activityId}`,
      //         text: "View Application",
      //       }}
      //     />
      //   );
      // } else if (
      //   activity.mx_Custom_1 &&
      //   config.CHECK_FOR_WONDERLINK.includes(
      //     JSON.parse(activity.mx_Custom_1).mx_CustomObject_1
      //   ) &&
      //   JSON.parse(activity.mx_Custom_1).mx_CustomObject_2 !=
      //     "Practical Nursing"
      // ) {
      //   // this case is for non-wonderlink
      //   console.log("Non-Wonderlink Case 2");
      //   return (
      //     <ApplicationCard
      //       setIframeYoutube={setIframeYoutube}
      //       setVideoModal={setVideoModal}
      //       shouldDownloadDocument={shouldDownloadDocument}
      //       educationalFunding={educationalFunding}
      //       applicationId={applicationId}
      //       user={userInfo}
      //       application={application}
      //       applicationStatus={applicationStatus}
      //       paymentStatus={paymentStatus}
      //       modifiedOn={lastUpdate}
      //       showTeas={true}
      //       applicationCTA={{
      //         link: `/Home/ApplicationForm/Preview/${activityId}`,
      //         text: "View Application",
      //       }}
      //     />
      //   );
      //   // setStage("Review Catalog Pending"); // this is has been done for application stages.
      //   // updateActivity("mx_Custom_2", "Review Catalog Pending", activityId);
      // }
      // new changes regarding schedule call session.
      if (
        activity &&
        (!activity.mx_Custom_32 ||
          (activity.mx_Custom_32 &&
            ["no", ""].indexOf(activity.mx_Custom_32.toLowerCase()) !== -1))
      ) {
        console.log("Changing for all wonderlic and non-wonderlic");
        return (
          <ApplicationCard
            setIframeYoutube={setIframeYoutube}
            setVideoModal={setVideoModal}
            shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            showTeas={true}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity &&
        (!activity.mx_Custom_6 ||
          (activity.mx_Custom_6 &&
            ["fail", ""].indexOf(activity.mx_Custom_6.toLowerCase()) !== -1)) &&
        activity.mx_Custom_32 &&
        ["yes"].indexOf(activity.mx_Custom_32.toLowerCase()) !== -1 &&
        activity.mx_Custom_33 &&
        ["yes"].indexOf(activity.mx_Custom_33.toLowerCase()) !== -1
      ) {
        // console.log("me here wond.", activity.mx_Custom_33);
        return (
          <ApplicationCard
            setIframeYoutube={setIframeYoutube}
            setVideoModal={setVideoModal}
            shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            assessmentOpenInNew={true}
            applicationCTA={{
              link:
                WonderlicTestLinks[campus] &&
                WonderlicTestLinks[campus][course] &&
                WonderlicTestLinks[campus][course],
              text: "Register & Take Wonderlic Test",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity &&
        activity.mx_Custom_32 &&
        ["yes"].indexOf(activity.mx_Custom_32.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_33 ||
          (activity.mx_Custom_33 &&
            [""].indexOf(activity.mx_Custom_32.toLowerCase()) !== -1))
      ) {
        // console.log(
        //   "Changing for all wonderlic and non-wonderlic after option selected."
        // );
        return (
          <ApplicationCard
            setIframeYoutube={setIframeYoutube}
            setVideoModal={setVideoModal}
            shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            showTeas={true}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_6 &&
        ["pass"].indexOf(activity?.mx_Custom_6?.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_10 ||
          activity.mx_Custom_10.toLowerCase() === "fail") &&
        config.TEAS_TEST_FOR_PROGRAMS[
          activity.mx_Custom_1 &&
            JSON.parse(activity.mx_Custom_1).mx_CustomObject_2
        ] // if current program requires teas test
      ) {
        return (
          <ApplicationCard
            setIframeYoutube={setIframeYoutube}
            setVideoModal={setVideoModal}
            shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            showTeas={true}
            assessmentOpenInNew={true}
            applicationCTA={{
              link: `https://www.atitesting.com/login`,
              text: "Create Your ATI Account / DO NOT Register for TEAS",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity?.mx_Custom_6?.toLowerCase() === "in review" ||
        activity?.mx_Custom_10?.toLowerCase() === "in review"
      ) {
        return (
          <ApplicationCard
            setIframeYoutube={setIframeYoutube}
            setVideoModal={setVideoModal}
            shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            showTeas={true}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else {
        /* removed this code this else is only here because we have commented other assessment. this will help as fallback. if user from portal set wonderlic to pass and frogot to change status to "Review Catalog pending" */
        setStage("Review Catalog Pending"); // this is has been done for application stages.
        updateActivity("mx_Custom_2", "Review Catalog Pending", activityId);
      }
      // dont remove this code it will including in future..
      /* else if (
        activity &&
        activity.mx_Custom_6 &&
        ["pass"].indexOf(activity.mx_Custom_6.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_7 || activity.mx_Custom_7.toLowerCase() === "fail")
      ) {
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            applicationCTA={{
              link: `/Home/Form/${activityId}`,
              text: "Take School Readiness Assessment",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_7 &&
        ["pass"].indexOf(activity.mx_Custom_7.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_8 || activity.mx_Custom_8.toLowerCase() === "fail")
      ) {
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            applicationCTA={{
              link: `/Home/Form/${activityId}`,
              text: "Take Computer and Online Assessment",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_8 &&
        ["pass"].indexOf(activity.mx_Custom_8.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_9 || activity.mx_Custom_9.toLowerCase() === "fail")
      ) {
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "Take Learning Style Assessment",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_9 &&
        ["pass"].indexOf(activity.mx_Custom_9.toLowerCase()) !== -1 &&
        (!activity.mx_Custom_10 ||
          activity.mx_Custom_10.toLowerCase() === "fail") &&
        config.TEAS_TEST_FOR_PROGRAMS[
          activity.mx_Custom_1 &&
            JSON.parse(activity.mx_Custom_1).mx_CustomObject_2
        ] // if current program requires teas test
      ) {
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            showTeas={true}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "Take TEAS Test Assessment",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_9 &&
        ["pass"].indexOf(activity.mx_Custom_9.toLowerCase()) !== -1 &&
        !config.TEAS_TEST_FOR_PROGRAMS[
          activity.mx_Custom_1 &&
            JSON.parse(activity.mx_Custom_1).mx_CustomObject_2
        ] // if current program requires teas test
      ) {
        /// fall back code if the lapp is not able to update the appliction status this will push the user state to review catalog
        setStage("Review Catalog Pending"); // this is has been done for application stages.
        updateActivity("mx_Custom_2", "Review Catalog Pending", activityId);

        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={"Review Catalog Pending"}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            applicationCTA={{
              link: `/Home/ReviewCatalogue/${activityId}`,
              text: "Review Catalog",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else if (
        activity.mx_Custom_10 &&
        activity.mx_Custom_10.toLowerCase() === "pass"
      ) {
        /// fall back code if the lapp is not able to update the appliction status this will push the user state to review catalog
        setStage("Review Catalog Pending"); // this is has been done for application stages.
        updateActivity("mx_Custom_2", "Review Catalog Pending", activityId);
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={"Review Catalog Pending"}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            applicationCTA={{
              link: `/Home/ReviewCatalogue/${activityId}`,
              text: "Review Catalog",
            }}
            applicationReview={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } else {
        return (
          <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
shouldDownloadDocument={shouldDownloadDocument}
            educationalFunding={educationalFunding}
            applicationId={applicationId}
            user={userInfo}
            application={application}
            applicationStatus={applicationStatus}
            paymentStatus={paymentStatus}
            modifiedOn={lastUpdate}
            // applicationCTA={{
            //   link: `/Home/Form/${activityId}`,
            //   text: "Take School Readiness Assessment",
            // }}
            applicationCTA={{
              link: `/Home/ApplicationForm/Preview/${activityId}`,
              text: "View Application",
            }}
          />
        );
      } */
    } else if (applicationStatus.toLowerCase() === "review catalog pending") {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ReviewCatalogue/${activityId}`,
            text: "Review Catalog",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      getMassSpecifFormLink(applicationStatus.toLowerCase(), activityId) && // if the massachusetts link is defined
      config.EA_MAP.STATES.indexOf(campus.toLowerCase()) !== -1 // check if user falls under massachusetts state.
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: getMassSpecifFormLink(
              applicationStatus.toLowerCase(),
              activityId
            ),
            text:
              "Massachusetts Documents " +
              getMassSpecifFormLink(
                applicationStatus.toLowerCase(),
                activityId,
                true
              ),
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "background check in process"
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: "#",
            text: "Background Check",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      [
        "documents pending",
        "documents in review",
        "educational funding",
      ].indexOf(applicationStatus.toLowerCase()) !== -1
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: `/Home/UploadDocumentForm/${activityId}`,
            text: "Upload Documents",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application ",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "acknowledgement form pending"
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: `/Home/AcknowledgementForm/${activityId}`,
            text: "Acknowledge Form",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "initiate background check"
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          initiateBGCheck={true}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: `#`,
            text: "Initiate Background check",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      /*  else if (
      applicationStatus.toLowerCase() === "background check to consider"
    ) {
      return (
        <ApplicationCard
setIframeYoutube={setIframeYoutube}
setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Sign/BackgroundCheck/${activityId}`,
            text: "E-Sign Background check",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } */
      applicationStatus.toLowerCase() === "enrollment agreement e-sign pending"
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          applicationCTA={{
            link: `/Home/EnrollmentAgreement/${activityId}`,
            text: "E-Sign Enrollment Agreement",
          }}
          applicationReview={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() ===
      "enrollment agreement parent e-sign pending"
    ) {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          applicationStatus={applicationStatus}
          paymentStatus={paymentStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          /*  applicationCTA={{
            link: `/Home/EnrollmentAgreement/${activityId}`,
            text: "E-Sign Enrollment Agreement",
          }} */
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else {
      return (
        <ApplicationCard
          setIframeYoutube={setIframeYoutube}
          setVideoModal={setVideoModal}
          shouldDownloadDocument={shouldDownloadDocument}
          educationalFunding={educationalFunding}
          applicationId={applicationId}
          user={userInfo}
          application={application}
          paymentStatus={paymentStatus}
          applicationStatus={applicationStatus}
          modifiedOn={lastUpdate}
          AGPdfUrl={AGPdfUrl}
          PCWPdfUrl={PCWPdfUrl}
          EAPdfUrl={EAPdfUrl}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    }
  };

  const handleDashboardActionButton = () => {
    let link;
    if (
      // stage === "" ||
      stage.toLowerCase() === "application pending" &&
      ["", "fee pending"].indexOf(paymentStatus.toLowerCase()) !== -1
    ) {
      link = `/Home/Form/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Continue Application
        </a>
      );
    } else if (
      ["application in review", "application saved"].indexOf(
        stage.toLowerCase()
      ) !== -1 &&
      ["", "fee pending"].indexOf(paymentStatus.toLowerCase()) !== -1 // inital payment is empty so this will always be true in this cond only
    ) {
      link = config.PAYMENT_WAIVED
        ? `/Home/ApplicationFeeSuccess/${activityId}`
        : `/Home/ApplicationFeeForm/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Next
          {/* Pay Application Fee */}
        </a>
      );
    } else if (
      [
        "application saved",
        "application approved",
        "application in review",
      ].indexOf(stage.toLowerCase()) !== -1 &&
      ["fee paid", "fee waived"].indexOf(paymentStatus.toLowerCase()) !== -1
    ) {
      let campusName =
        applicationData[0].ActivityFields &&
        applicationData[0].ActivityFields.mx_Custom_1 &&
        JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
          .mx_CustomObject_1;
      
      let programName =
        applicationData[0].ActivityFields &&
        applicationData[0].ActivityFields.mx_Custom_1 &&
        JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
          .mx_CustomObject_2;
      if (
        programName &&
        Object.keys(config.CHECK_FOR_WONDERLINK_TEAS_BOTH).includes(
          programName
        ) &&
        campusName &&
        config.CHECK_FOR_WONDERLINK_TEAS_BOTH[programName].includes(
          campusName
        ) &&
        applicationData[0].ActivityFields &&
        (!applicationData[0].ActivityFields.mx_Custom_32 ||
          (applicationData[0].ActivityFields.mx_Custom_32 &&
            ["no", ""].indexOf(
              applicationData[0].ActivityFields.mx_Custom_32.toLowerCase()
            ) !== -1))
      ) {
        link = `/Home/WatchVideoForm/${activityId}`;
        return (
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            Next: Program Information Session
          </a>
        );
      } else if (
        // applicationData[0].ActivityFields &&
        // applicationData[0].ActivityFields.mx_Custom_1 &&
        // (config.CHECK_FOR_NOT_WONDERLINK.includes(
        //   JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
        //     .mx_CustomObject_1
        // ) ||
        //   (config.CHECK_FOR_WONDERLINK.includes(
        //     JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
        //       .mx_CustomObject_1
        //   ) &&
        //     JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
        //       .mx_CustomObject_2 != "Practical Nursing")) &&
        // paymentStatus === "Fee Waived" &&
        applicationData[0].ActivityFields &&
        (!applicationData[0].ActivityFields.mx_Custom_32 ||
          (applicationData[0].ActivityFields.mx_Custom_32 &&
            ["no", ""].indexOf(
              applicationData[0].ActivityFields.mx_Custom_32.toLowerCase()
            ) !== -1)) &&
        applicationData[0].ActivityFields.ActivityEvent_Note !=
          "Contact Method Selected"
      ) {
        link = `/Home/PhoneCallForm/${activityId}`;
        return (
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            Next: Schedule Info Session
          </a>
        );
      } else if (
        //  applicationData[0].ActivityFields &&
        // (!applicationData[0].ActivityFields.mx_Custom_6 ||
        //   (applicationData[0].ActivityFields.mx_Custom_6 &&
        //     [""].indexOf(
        //       applicationData[0].ActivityFields.mx_Custom_6.toLowerCase()
        //     ) !== -1)) &&
        applicationData[0].ActivityFields &&
        applicationData[0].ActivityFields.mx_Custom_32 &&
        ["yes"].indexOf(
          applicationData[0].ActivityFields.mx_Custom_32.toLowerCase()
        ) !== -1 &&
        (!applicationData[0].ActivityFields.mx_Custom_33 ||
          (applicationData[0].ActivityFields.mx_Custom_33 &&
            ["no", ""].indexOf(
              applicationData[0].ActivityFields.mx_Custom_33.toLowerCase()
            ) !== -1)) &&
        applicationData[0].ActivityFields.ActivityEvent_Note ===
          "Contact Method Selected"
      ) {
        link = `/Home/ApplicationForm/Preview/${activityId}`;
        return (
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            {`View Application [ Pending Info Session ]`}
          </a>
        );
      } else if (
        applicationData[0].ActivityFields &&
        applicationData[0].ActivityFields.mx_Custom_32 &&
        ["yes"].indexOf(
          applicationData[0].ActivityFields.mx_Custom_32.toLowerCase()
        ) !== -1 &&
        applicationData[0].ActivityFields.mx_Custom_33 &&
        ["yes"].indexOf(
          applicationData[0].ActivityFields.mx_Custom_33.toLowerCase()
        ) !== -1 &&
        (!applicationData[0].ActivityFields.mx_Custom_6 ||
          (applicationData[0].ActivityFields.mx_Custom_6 &&
            ["fail", ""].indexOf(
              applicationData[0].ActivityFields.mx_Custom_6.toLowerCase()
            ) !== -1)) &&
        applicationData[0].ActivityFields.ActivityEvent_Note !==
          "Contact Method Selected"
      ) {
        link =
          WonderlicTestLinks[campus] &&
          WonderlicTestLinks[campus][course] &&
          WonderlicTestLinks[campus][course];
        return (
          <>
            <a href={link} className="dashboard-link" rel="noopener noreferrer">
              Next: Register & Take Wonderlic Test
            </a>
            <HelpIcon status={"Register & Take Wonderlic Test"} />
          </>
        );
      } else if (
        applicationData[0].ActivityFields.mx_Custom_6 &&
        ["pass"].indexOf(
          applicationData[0].ActivityFields?.mx_Custom_6?.toLowerCase()
        ) !== -1 &&
        (!applicationData[0].ActivityFields.mx_Custom_10 ||
          applicationData[0].ActivityFields.mx_Custom_10.toLowerCase() ===
            "fail") &&
        config.TEAS_TEST_FOR_PROGRAMS[
          applicationData[0].ActivityFields.mx_Custom_1 &&
            JSON.parse(applicationData[0].ActivityFields.mx_Custom_1)
              .mx_CustomObject_2
        ] // if current program requires teas test
      ) {
        link = "https://www.atitesting.com/login";
        return (
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            Next: Create Your ATI Account / DO NOT Register for TEAS
          </a>
        );
      } else if (
        applicationData[0].ActivityFields?.mx_Custom_6?.toLowerCase() ===
          "in review" ||
        applicationData[0].ActivityFields?.mx_Custom_10?.toLowerCase() ===
          "in review"
      ) {
        link = `/Home/ApplicationForm/Preview/${activityId}`;
        return (
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            View Application
          </a>
        );
      }
      // else {}
    } else if (stage.toLowerCase() === "review catalog pending") {
      link = `/Home/ReviewCatalogue/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Next: Review Catalog
        </a>
      );
    } else if (
      getMassSpecifFormLink(stage.toLowerCase(), activityId) && // if the massachusetts link is defined
      config.EA_MAP.STATES.indexOf(campus.toLowerCase()) !== -1
    ) {
      link = getMassSpecifFormLink(stage.toLowerCase(), activityId);
      return (
        <>
          <a href={link} className="dashboard-link" rel="noopener noreferrer">
            {"Next: Massachusetts Documents " +
              getMassSpecifFormLink(stage.toLowerCase(), activityId, true)}
          </a>
          <HelpIcon
            status={
              "Next: Massachusetts Documents " +
              getMassSpecifFormLink(stage.toLowerCase(), activityId, true)
            }
          />
        </>
      );
    } else if (stage.toLowerCase() === "background check in process") {
      return (
        <a
          className="dashboard-link"
          rel="noopener noreferrer"
          onClick={() => setCheckInProgress(true)}>
          Next: Background Check
        </a>
      );
    } else if (
      [
        "documents pending",
        "documents in review",
        "educational funding",
      ].indexOf(stage.toLowerCase()) !== -1
    ) {
      link = `/Home/UploadDocumentForm/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Next: Upload Documents
        </a>
      );
    } else if (stage.toLowerCase() === "acknowledgement form pending") {
      link = `/Home/AcknowledgementForm/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Next: Acknowledge Form
        </a>
      );
    } else if (stage.toLowerCase() === "initiate background check") {
      link = "#";
      return (
        <a
          href={link}
          onClick={() => setBackgroundCheck(true)}
          className="dashboard-link"
          rel="noopener noreferrer">
          Next: Initiate Background check
        </a>
      );
    } else if (stage.toLowerCase() === "enrollment agreement e-sign pending") {
      link = `/Home/EnrollmentAgreement/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          Next: E-Sign Enrollment Agreement
        </a>
      );
    } else if (
      stage.toLowerCase() === "enrollment agreement parent e-sign pending"
    ) {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (stage.toLowerCase() === "enrollment agreement in process") {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (
      stage.toLowerCase() === "enrollment agreement e-sign in review"
    ) {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (stage.toLowerCase() === "accepted") {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (stage.toLowerCase() === "in review") {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (stage.toLowerCase() === "background check to consider") {
      link = `/Home/ApplicationForm/Preview/${activityId}`;
      return (
        <a href={link} className="dashboard-link" rel="noopener noreferrer">
          View Application
        </a>
      );
    } else if (stage.toLowerCase() == "reenroll") {
      return (
        <a
          disabled="true"
          className="dashboard-link"
          rel="noopener noreferrer"
          style={{ opacity: 0.5 }}>
          Continue Application
        </a>
      );
    }
  };

  const HelpIcon = ({ status }) => {
    const filterArr = {
      "Register & Take Wonderlic Test":
        "https://www.youtube.com/embed/ee5JGOE4Ynw",
      "Massachusetts Documents 1": "https://www.youtube.com/embed/uvi8UuwSKrE",
      "Massachusetts Documents 2": "https://www.youtube.com/embed/uvi8UuwSKrE",
      "Massachusetts Documents 3": "https://www.youtube.com/embed/uvi8UuwSKrE",
    };
    const result = filterArr[status];
    return result ? (
      <a
        href="/"
        className="ml-2"
        style={{ color: "#6c757d " }}
        onClick={(e) => setYoutube(e, result)}>
        <i className="fa fa-info-circle" style={{ color: "#6c757d" }}></i>
      </a>
    ) : (
      ""
    );
  };

  const setYoutube = (e, link) => {
    e.preventDefault();
    setIframeYoutube(link);
    setVideoModal(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen    text="Dashboard is loading, Please wait.." //prettier-ignore
          // defaultTimer={true}
          // timer={50000}
        /> //prettier-ignore
      ) : (
        <div className="application-container">
          <div className="application-card-list">
            {/* <div className="container"></div> */}
            <div className="container pt-4 pt-lg-5">
              <div className="col-12 mt-4 ml-sm-auto contact-wrapper">
                {stage && <ContactCareerProgram />}
              </div>
              <div className="row d-flex flex-sm-row flex-column ml-sm-2 mb-3 mt-4 pt-2">
                <h3 className="bolder1 text-dark">Your Application</h3>

                {/* {stage && <ContactCareerProgram />} */}
                <div className="ml-sm-auto pr-3">
                  <div className="dashboard-link-wrapper">
                    {handleDashboardActionButton()}
                  </div>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col-12  col-lg-4">
                  {/* pass null to disabled all.. inital should be null */}
                  <ApplicationStages
                    state={campus && campus.toLowerCase()}
                    currentStage={stage}
                    doBackgroundCheck={doBackgroundCheck}
                    count={count}
                    paymentStatus={paymentStatus}
                  />
                </div>
                <div className="col-12 col-lg-8 mt-md-4 mt-lg-0">
                  {((stage === null || count === 0) && <DashboardBanner />) ||
                    applicationData.map((application, index) =>
                      renderApplication(application, index, userDetails)
                    )[0]}
                </div>
                {/* <div className="col-12 mt-4 ml-sm-auto contact-wrapper">
                  {stage && <ContactCareerProgram />}
                </div> */}
                <div className="col-12">
                  <NoticeModal
                    setIframeYoutube={setIframeYoutube}
                    setVideoModal={setVideoModal}
                  />
                  <VideoModal
                    src={iFrameYoutube}
                    videoModal={videoModal}
                    setVideoModal={setVideoModal}
                  />
                </div>
              </div>
            </div>
          </div>
          <CheckInProgress
            checkInProgress={checkInProgress}
            setCheckInProgress={setCheckInProgress}
          />
          <BackgroundCheck
            BackgroundCheck={backgroundCheck}
            setBackgroundCheck={setBackgroundCheck}
            activityId={activityId}
          />
        </div>
      )}
    </>
  );
};

export default ApplicationHistory;
