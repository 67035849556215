var Action = {
  SigninModal: {
    Show: "SHOW_SIGNIN_MODAL",
    Change: "CHANGE_SIGNIN_MODAL",
    Reset: "RESET_SIGNIN_MODAL",
  },
  Register: {
    OTP: {
      Empty: "EMPTY_MOBILE_NUMBER",
      Invalid: "INVALID_MOBILE_NUMBER",
      Valid: "VALID_MOBILE_NUMBER",
      Send: "SENDING_OTP",
      Received: "OTP_RECEIVED",
      Reset: "RESET_OTP_STATE",
    },
    Modal: {
      Registering: "REGISTERING",
      Registered: "REGISTERED",
      ErrorRegistering: "ERROR_REGISTERING",
    },
  },
  Login: {
    SigningIn: "SIGNING_IN",
    SignedIn: "SIGNED_IN",
    ErrorSigningIn: "ERROR_SIGNING_IN",
    Reset: "RESET_LOGIN_STATE",
  },
  ForgotPassword: {
    GeneratingLink: "GENERATING_RESET_PASSWORD_LINK",
    SentLink: "SENT_RESET_PASSWORD_LINK",
    ErrorGeneratingLink: "ERROR_GENERATING_RESET_PASSWORD_LINK",
    Reset: "RESET_FORGOT_PASSWORD_STATE",
  },
  AuthSessionInfo: {
    IsAuthenticated: "IS_AUTHENTICATED",
    UpdateLoginInfo: "UPDATE_LOGIN_INFO",
  },
  LeadDetails: {
    Update: "UPDATE_LEAD_DETAILS",
  },
  LoanDetails: {
    Update: "UPDATE_LOAN_DETAILS",
  },
  ApplicationStatus: {
    Update: "UPDATE_APPLICATION_STATUS",
  },
  Logout: {
    Request: "REQUEST_LOGOUT",
  },
};

function changeSigninModal(name) {
  var action = {
    type: Action.SigninModal.Change,
    name,
  };
  return action;
}

function showModal(show) {
  var action = {
    type: Action.SigninModal.Show,
    show,
  };
  return action;
}

function resetSigninModal() {
  return {
    type: Action.SigninModal.Reset,
  };
}

var Register = {};
Register.OTP = {
  validateMobileNumber: function (type, validationInfo) {
    var action = {
      type: type,
      validationInfo,
    };
    return action;
  },
  send: function (send, validationInfo) {
    var action = {
      type: Action.Register.OTP.Send,
      send,
      validationInfo,
    };
    return action;
  },
  received: function (received, validationInfo) {
    var action = {
      type: Action.Register.OTP.Received,
      received,
      validationInfo,
    };
    return action;
  },
  reset: function () {
    return {
      type: Action.Register.OTP.Reset,
    };
  },
};
Register.Modal = {
  changeState: function (type, registerModalInfo) {
    return {
      type: type,
      registerModalInfo,
    };
  },
};

var LoginUI = {
  changeLoginState: function (type, state) {
    return {
      type: type,
      state,
    };
  },
  errorSigningIn: function (errorMsg) {
    return {
      type: Action.Login.ErrorSigningIn,
      state: "ERROR_SIGNING_IN",
      errorMsg: errorMsg,
    };
  },
  resetState: function () {
    return {
      type: Action.Login.Reset,
    };
  },
};

var ForgotPassword = {
  changeState: function (type, forgotPasswordInfo) {
    return {
      type: type,
      forgotPasswordInfo,
    };
  },
  reset: function () {
    return {
      type: Action.ForgotPassword.Reset,
    };
  },
};

var AuthSessionInfo = {
  isAuthenticated: function (isAuthenticated) {
    return {
      type: Action.AuthSessionInfo.IsAuthenticated,
      isAuthenticated,
    };
  },
  updateLoginInfo: function (loginInfo) {
    return {
      type: Action.AuthSessionInfo.UpdateLoginInfo,
      loginInfo,
    };
  },
};

var LeadDetails = {
  update: function (leadDetails) {
    // console.log("leadDetails", leadDetails);
    return {
      type: Action.LeadDetails.Update,
      leadDetails,
    };
  },
};

var LoanDetails = {
  update: function (loanDetails) {
    return {
      type: Action.LoanDetails.Update,
      loanDetails,
    };
  },
};

var ApplicationStatus = {
  update: function (applicationStatusInfo) {
    return {
      type: Action.ApplicationStatus.Update,
      applicationStatusInfo,
    };
  },
};

var Logout = {
  requestLogout: function () {
    return {
      type: Action.Logout.Request,
    };
  },
};

export {
  Action,
  changeSigninModal,
  showModal,
  resetSigninModal,
  Register,
  LoginUI,
  ForgotPassword,
  AuthSessionInfo,
  LeadDetails,
  LoanDetails,
  ApplicationStatus,
  Logout,
};
